import store from "@/store";

export const Permission = {
    async mounted(el, binding) {
        if (store.getters.getProfileData.is_superuser) {
            return;
        }
        if (binding.value === undefined) {
            return;
        }
        if (Array.isArray(binding.value)) { // Если значение является массивом
            if (binding.modifiers.one) {
                const isNoAccess = binding.value.some((permission) => store.getters.getCurrentUserPermissions?.[permission]); //если все permission будут false, то isNoAccess будет true, например (["false", "false", "false"])
                if (!isNoAccess) {
                    checkPermission(el, binding);
                    return;
                }
            } else {
                const isNoAccess = binding.value.some((permission) => !store.getters.getCurrentUserPermissions?.[permission]); //если хотя бы один permission будет false, то isNoAccess будет true, например (["true", "false", "true"])
                if (isNoAccess) {
                    checkPermission(el, binding);
                    return;
                }
            }
        }
        if (typeof binding.value === "string") { // Если значение является строкой
            if (!store.getters.getCurrentUserPermissions?.[binding.value]) {
                checkPermission(el, binding);
            }
            return;
        }
    }
}

function checkPermission(el, binding) {
    switch (true) {
        case binding.modifiers.disabled:
            el.setAttribute("disabled", "");
            return;
        default:
            el.parentNode.removeChild(el);
            break;
    }
}