<template>
    <Dialog
        v-model:visible="localVisible"
        modal
        header="Header"
        class="dialog"
        :closable="false"
        :pt="{
            headericons: {
                style: 'display: none;'
            }
        }"
    >
        <template #header>
            <div class="dialog__header">
                <span>Восстановление пароля</span>
            </div>
        </template>
        <template #closeicon>
            <div style="display: none;"></div>
        </template>
        <div class="dialog__body" style="display: flex; flex-direction: column; gap: 16px;">
            <div class="additional__tab_items">
                <div class="additional__tab_item" style="width: 100%;">
                    <div>
                        <p style="padding-bottom: 10px;">
                            Если вы забыли свой пароль, не волнуйтесь. Вы можете восстановить доступ к своему аккаунту одним из следующих способов:
                        </p>
                        <p style="padding-bottom: 5px; padding-left: 10px;">
                            1. Перейдите по ссылке <a href="https://help.ecomedis.ru" style="text-decoration: underline; color: #6C69EB;">https://help.ecomedis.ru</a> для восстановления пароля.
                        </p>
                        <p style="padding-bottom: 5px; padding-left: 10px;">
                            2. Отправьте запрос на наш адрес электронной почты: <a href="mailto:help@ecomedis.ru" style="text-decoration: underline; color: #6C69EB;">help@ecomedis.ru</a>.
                        </p>
                        <p style="padding-bottom: 5px; padding-left: 10px;">
                            3. Свяжитесь с нашей службой технической поддержки по телефону: <a href="tel: +7 800 301-25-27" style="text-decoration: underline; color: #6C69EB;">+7 800 301-25-27</a>. Наши специалисты помогут вам восстановить доступ к вашему аккаунту.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button
                class="button__purple"
                label="Закрыть"
                @click="closeDialog"
            />
        </template>
    </Dialog>
</template>

<script>
    export default {
        name: "RestorePasswordDialog",
        props: {
            visible: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                localVisible: this.visible // Локальная копия
            };
        },
        methods: {
            closeDialog() {
                this.localVisible = false; // Изменяем локальную копию
                this.$emit('update:visible', false); // Сообщаем об изменении родителю
            }
        },
        watch: {
            // Синхронизация изменений из родителя
            visible(newVal) {
                this.localVisible = newVal;
            }
        },
    }
</script>