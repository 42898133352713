<template>
    <RestorePasswordDialog 
        v-model:visible="isRestorePasswordDialogVisible" 
    />
    <CheckingPositionDialog 
        v-model:visible="isCheckingPositionDialogVisible"
        v-model:employeePositions="employeePositions"
        v-model:selectedEmployeePositionsId="selectedEmployeePositionsId"
        :createUserSession="createUserSession"
    />
    <div class="page" data-testid="authorization">
        <div class="top">
            <div class="authorization">
                <div class="authorization__logo">
                    <img
                        src="../assets/img/MORFIS_LOGO.svg"
                        alt="logo"
                        class="authorization__logo_img"
                    />
                </div>
                <div class="authorization__form">
                    <form
                        ref="authorization-form-ref"
                        action="POST"
                        class="form"
                        @keydown.prevent.enter="checkingPosition"
                    >
                        <h3 class="form__header">
                            Авторизация
                        </h3>
                        {{ getErrorStatus }}
                        <div class="authorization__form_row" >
                            <AppInputText
                                inputTextId="userLoginId"
                                inputRef="userLoginRef"
                                inputLabelTitle="Логин"
                                :vModelInputText="login"
                                :isInvalidInput="getLoginFieldFilledInStatus || getErrorStatus !== ''"
                                :inputHelpText="getErrorStatus !== '' ? getErrorStatus : 'Необходимо заполнить поле'"
                                isRequiredStyle
                                @onInput="updateLogin"
                            />
                        </div>
                        <div class="authorization__form_row">
                            <AppInputText
                                inputTextId="userPasswordId"
                                inputRef="userPasswordRef"
                                inputLabelTitle="Пароль"
                                :vModelInputText="password"
                                :isInvalidInput="getPasswordFieldFilledInStatus || getErrorStatus !== ''"
                                :inputHelpText="getErrorStatus !== '' ? getErrorStatus : 'Необходимо заполнить поле'"
                                isPasswordInput
                                isRequiredStyle
                                @onInput="updatePassword"
                            />
                        </div>
                        <Button
                            class="button__purple"
                            style="width: 100%;"
                            :loading="getIsComponentLoaderButtonActive"
                            label="Войти"
                            @click="checkingPosition()"
                        />
                        <div 
                            class="authorization__form_forgot authorization__form_forgot--link"
                            style="cursor: pointer;"
                            @click="isRestorePasswordDialogVisible = true"
                        >
                            Забыли пароль?
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getLifetimeCasesLink } from "@/router";
import { api } from "@/api";
import { mapActions, mapGetters } from "vuex";
import AppInputText from "@/components/AppInputText.vue";
import RestorePasswordDialog from '@/components/RestorePasswordDialog';
import CheckingPositionDialog from '@/components/CheckingPositionDialog';

export default {
    name: "Authorization",
    data() {
        return {
            lifetimeCasesLink: getLifetimeCasesLink(),
            isRestorePasswordDialogVisible: false,
            isCheckingPositionDialogVisible: false,
            employeePositions: [],
            selectedEmployeePositionsId: null,
            userData:  {},
            login: "",
            password: "",
        };
    },
    components: {
        RestorePasswordDialog,
        CheckingPositionDialog,
        AppInputText
    },
    computed: {
        ...mapGetters([
            "getErrorStatus",
            "getAuthorizationData",
            "getLoginFieldFilledInStatus",
            "getPasswordFieldFilledInStatus",
            "getIsComponentLoaderButtonActive"
        ]),
        getDisabledShowPasswordStatus() {
            return this.getAuthorizationData.password === undefined || this.getAuthorizationData.password?.length === 0;
        }
    },
    methods: {
        ...mapActions([
            "getTokenData",
            "updateAuthorizationData",
            "updateLoginFieldFilledInStatus",
            "updatePasswordFieldFilledInStatus",
            "fetchAccessToken",
            "updateProfileData"
        ]),
        updateLogin(value) {
            this.login = value;
            this.updateLoginFieldFilledInStatus(false);
            this.updateAuthorizationData({ username: value });
        },
        updatePassword(value) {
            this.password = value;
            this.updatePasswordFieldFilledInStatus(false);
            this.updateAuthorizationData({ password: value }); 
        },
        async checkingPosition() {
            this.updateAuthorizationData({ 
                username: this.login,
                password: this.password 
            });
            if (!this.getAuthorizationData.username || !this.getAuthorizationData.password) {
                if(!this.getAuthorizationData.username) {
                    this.updateLoginFieldFilledInStatus(true);
                }
                if(!this.getAuthorizationData.password) {
                    this.updatePasswordFieldFilledInStatus(true);
                }
                return;
            }
            const tokenData = await this.getTokenData();
            try {
                if (this.getErrorStatus !== "") {
                    return;
                }
                const userId = tokenData.user.id;
                const user = await api.getUser(userId, tokenData.access);
                if (user.employee.employee_positions.length === 0) {
                    console.log(user.employee.employee_positions);
                    this.$router.push(getLifetimeCasesLink());
                }
                if (user.employee.employee_positions.length === 1) {
                    this.selectedEmployeePositionsId = user.employee.employee_positions[0].id;
                    this.createUserSession();
                }
                if (user.employee.employee_positions.length > 1) {
                    this.employeePositions = user.employee.employee_positions;
                    this.isCheckingPositionDialogVisible = true;
                }
                this.userData = user;
            } catch (error) {
                console.log('%cError: ', 'color: green;', error);
            }
        },
        async createUserSession() {
            const token = await this.fetchAccessToken();
            try {
                const employeePositionsData = {
                    employee_position: this.selectedEmployeePositionsId
                };
                const userSession = await api.postUserSession(employeePositionsData, token);
                if (userSession.id) {
                    this.localVisible = false;
                    this.$emit('update:visible', false);
                    localStorage.setItem("isAuthorizedUser", true);
                    localStorage.setItem('employeePositionId', JSON.stringify(userSession.employee_position));
                    this.updateProfileData(this.userData);
                    this.$router.push(getLifetimeCasesLink());
                }
            } catch (error) {
                console.log('%cError: ', 'color: green;', error);
            }
        },
    },
};
</script>

<style lang="sass">
@import "@/assets/css/_vars.sass"
.authorization
    display: flex
    justify-content: space-between
    align-items: center
    width: 680px
    height: 328px
    padding: 8px
    gap: 8px
    background: $authorizationBackground
    border-radius: 16px
    &__logo
        display: flex
        align-items: center
        justify-content: center
        width: 50%
        &_img
            width: 260px
    &__form
        background-color: $authorizationFormBackground
        border-radius: 8px
        width: 50%
        height: 100%
        padding: 48px 40px 0 40px
        &_row
            position: relative
            width: 100%
            margin-bottom: 12px
        &_forgot
            display: flex
            justify-content: flex-end
            width: 100%
        &_forgot--link
            color: #7952a1
            font-size: 12px
            margin-top: 12px
            transition: all 0.2s ease
            text-decoration: underline
            &:hover
                color: $authorizationForgotLinkColor
        .form
            display: flex
            flex-direction: column
            align-items: center
            height: 100%
            &__header
                margin-bottom: 24px
                font-size: 20px
                font-style: normal
                font-weight: 700
                line-height: 28px
                letter-spacing: -0.4px
            &__row
                position: relative
                margin-bottom: 30px
.icon__eye
    display: flex
    font-size: 16px
.button__show_password
    position: absolute
    top: 11px
    right: 16px
    cursor: pointer
    background-color: white
.button__show_password:disabled .icon__eye
    cursor: text
.authorization__form_input
    display: flex
    width: 100%
    height: 38px
    padding: 12px 16px 12px 16px
    font-size: 13px
    font-weight: 400
    line-height: 1.5
    background-color: $authorizationInputBackgroundColor
    background-clip: padding-box
    border: 1px solid $authorizationInputBorderColor
    border-radius: 4px
    box-shadow: inset 0 0 0 50px #fff
    margin-bottom: 24px
    &:focus
        border-color: $authorizationInputFocusBorderColor
    &--label
        position: absolute
        top: 12px
        left: 8px
        font-size: 12px
        padding: 0 8px
        color: $authorizationLabelPlaceholderTextColor
        background-color: $authorizationLabelPlaceholderBorderColor
        pointer-events: none
        transform-origin: 0 50%
        transition: transform 200ms, color 200ms
.authorization__form_input:focus ~ .authorization__form_input--label,
.authorization__form_input:not(:placeholder-shown) ~ .authorization__form_input--label
    transform: translateY(-19px) translateX(0px)
.authorization__form_input:placeholder-shown ~ .label__invalid
    transform: translateY(-19px) translateX(0px)
.authorization__form_input:not(:placeholder-shown) ~ .authorization__form_input--label
    color: $authorizationLabelTextColor
.authorization__form_input:not(:placeholder-shown) ~ .label__invalid
    color: $authorizationLabelInvalidTextColor
.authorization__form_input:focus ~ .authorization__form_input--label
    color: $authorizationLabelTextColor
.authorization__form_input:focus ~ .label__invalid
    color: $authorizationLabelInvalidTextColor
.login__button
    width: 100%
    height: 38px
    text-transform: none
    padding: 12px 15px
    font-size: 14px
    font-weight: 400
    background-color: $authorizationLoginButtonBackgroundColor
    &:hover
        background-color: $authorizationLoginButtonHoverBackgroundColor
.field__invalid
    border-color: $authorizationInputInvalidBorderColor !important
.label__invalid
    color: $authorizationLabelInvalidTextColor
.notification__invalid
    top: 36px
    font-size: 10px
.loader__button_position
    position: absolute
    left: -17px
    top: 2px
</style>