import { api } from "@/api";
export default {
    state: {
        profileData: {},
        userId: null,
        currentEmployeePosition: null,
        currentEmployeeDepartment: null,
        currentEmployeeOffset: null,
    },
    actions: {
        async fetchProfileData({ commit, dispatch }) {
            const accessTokenData = localStorage.accessTokenData;
            if (accessTokenData && JSON.parse(accessTokenData).access) {
                try {
                    const userId = localStorage.userData;
                    const profileData = await api.getUser(JSON.parse(userId), JSON.parse(accessTokenData).access);
                    if (profileData === 401 || profileData === 400) {
                        localStorage.removeItem("accessTokenData");
                        localStorage.removeItem("userAuth");
                        localStorage.removeItem("vuex");
                        document.cookie = `refreshTokenData=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
                        return;
                    }
                    localStorage.removeItem("vuex");
                    commit("addProfileData", profileData);
                    dispatch("defineUserPermissions", profileData.employee.employee_positions);
                } catch (e) {
                    console.error(e);
                    if (e.status === 401 || e.status === 400) {
                        localStorage.removeItem("accessTokenData");
                        localStorage.removeItem("userAuth");
                        document.cookie = `refreshTokenData=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
                    }
                }
            }
        },
        async addUsetData({ dispatch }, userData) {
            try {
                const token = await dispatch('getAccessToken');
                if (token) {
                    const user = await api.postUser(userData, token);
                    if (typeof user === 'object') {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return false;
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        async updatePartialUserDataById({ dispatch }, updatedUserData) {
            if (JSON.parse(localStorage.accessTokenData).access) {
                try {
                    console.log(updatedUserData, JSON.parse(localStorage.accessTokenData).access);
                    api.patchUser(updatedUserData, JSON.parse(localStorage.accessTokenData).access).
                    then(() => {
                        dispatch("fetchProfileData");
                    });

                } catch (e) {
                    console.error(e, e.message);
                    return e;
                }
            }
        },
        updateProfileData({ commit }, profileData) {
            commit("addProfileData", profileData);
        },
        updateUserId({ commit }, id) {
            commit("addUserId", id);
        },
        updateCurrentEmployeePosition({ commit }, position) {
            commit("addCurrentEmployeePosition", position);
        },
        updateCurrentEmployeeOffset({ commit }, offset) {
            commit("addCurrentEmployeeOffset", offset);
        },
    },
    mutations: {
        addProfileData(state, profileData) {
            state.profileData = profileData;
        },
        addUserId(state, id) {
            state.userId = id;
        },
        addCurrentEmployeePosition(state, position) {
            state.currentEmployeePosition = position;
            if (state.profileData.id) {
                state.currentEmployeeDepartment = state.profileData.employee.employee_positions.filter(position => position.id === state.currentEmployeePosition.id)[0]?.department;
            }
        },
        addCurrentEmployeeOffset(state, offset) {
            state.currentEmployeeOffset = offset;
        }
    },
    getters: {
        getProfileData(state) {
            return state.profileData;
        },
        getUserId(state) {
            return state.userId;
        },
        getCurrentEmployeePosition(state) {
            return state.currentEmployeePosition;
        },
        getCurrentEmployeeDepartment(state) {
            return state.currentEmployeeDepartment;
        },
        getCurrentEmployeeOffset(state) {
            return state.currentEmployeeOffset;
        }
    }
}