import { api } from "@/api";


export default {
    state: {
        settingsUsers: {},
        userById: null,
        settingsUsersTotalPages: null,
        isSettingsUsersLoading: false,
    },
    actions: {
        async fetchSettingsUser({ dispatch, commit }, queryParams) {
            commit("updateSettingsUsersLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const users = await api.getUsers(queryParams, token);
                    commit("changeSettingsUsers", users.results);
                    commit("changeSettingsUsersTotalPages", users.total_pages);
                    commit("updateSettingsUsersLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsUsersLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchSettingsUserById({ dispatch, commit }, userId) {
            commit("updateSettingsUsersLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const userById = await api.getUser(userId, token);
                    commit("changeSettingsUserById", userById);
                    commit("updateSettingsUsersLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsUsersLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMoreSettingsUsers({ dispatch, commit }, queryParams) {
            commit("updateSettingsUsersLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const users = await api.getUsers(queryParams, token);
                    commit("updateMoreSettingsUsers", users.results);
                    commit("updateSettingsUsersLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSettingsUsersLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSettingsUsersLoader", false);
            }

        },
        async addSettingsUsers({ dispatch, commit }, user ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const userData = await api.postUser(user, token);
                    if (typeof userData === "object") {
                        commit("addNewSettingsUsers", userData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async editSettingsUsers({ dispatch, commit }, [user, userId] ) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const userData = await api.patchUser(user, userId, token);
                    if (typeof userData === "object") {
                        commit("updateSettingsUsers", userData);
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        changeSettingsUsers(state, settingsUsers) {
            state.settingsUsers = settingsUsers;
        },
        updateMoreSettingsUsers(state, settingsUsers) {
            state.settingsUsers = [...state.settingsUsers, ...settingsUsers];
        },
        changeSettingsUsersTotalPages(state, settingsUsersTotalPages) {
            state.settingsUsersTotalPages = settingsUsersTotalPages;
        },
        updateSettingsUsersLoader(state, condition) {
            state.isSettingsUsersLoading = condition;
        },
        updateSettingsUsers(state, user) {
            const index = state.settingsUsers.findIndex(item => item.id === user.id);
            if (index !== -1) {
                const updatedMaterial = { ...user }; // Создаем копию объекта user
                const uniqueId = Object.values(updatedMaterial).join('_'); // Создаем уникальный идентификатор на основе значений объекта
                updatedMaterial.uniqueId = uniqueId; // Добавляем новое поле uniqueId к объекту
                state.settingsUsers.splice(index, 1, updatedMaterial); // Заменяем объект в массиве
            }
        },
        addNewSettingsUsers(state, user) {
            state.settingsUsers.unshift(user);
        },
        changeSettingsUserById(state, userById) {
            state.userById = userById;
        }
    },
    getters: {
        getSettingsUsers(state) {
            return state.settingsUsers;
        },
        getSettingsUsersTotalPages(state) {
            return state.settingsUsersTotalPages;
        },
        getSettingsUsersLoaderStatus(state) {
            return state.isSettingsUsersLoading;
        },
        getUserById(state) {
            return state.userById;
        }
    }
}