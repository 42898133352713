<template>
    <Dialog
        v-model:visible="localVisible"
        modal
        header="Header"
        class="dialog"
        :closable="false"
        :pt="{
            headericons: {
                style: 'display: none;'
            }
        }"
    >
        <template #header>
            <div class="dialog__header">
                <span>Выбор должности</span>
            </div>
        </template>
        <template #closeicon>
            <div style="display: none;"></div>
        </template>
        <div class="dialog__body" style="display: flex; flex-direction: column; gap: 16px;">
            <div style="width: 100%;">
                <AppDropdown
                    :selectOptionList="employeePositions"
                    :optionLabel="'position_name'"
                    dontShowClearButton
                    :defaultValue="employeePositions[0]"
                    @updateDropdownItem="setSelectedEmployeePositions"
                >
                    <template #optionitem="{data}">
                        <div style="font-size: 12px; font-weight: 500; color: #6A3F97;">
                            {{ data.option.position_name }}
                        </div>
                        <div style="font-size: 12px; font-weight: 500;">
                            {{ data.option.department.full_name }}
                        </div>
                    </template>
                </AppDropdown>
            </div>
        </div>
        <template #footer>
            <Button
                class="button__purple"
                label="Отмена"
                @click="closeDialog"
            />
            <Button
                class="button__purple"
                :disabled="selectedEmployeePositionsId === null"
                @click="createUserSession"
            >
                <p>Продолжить</p>
                <i
                    class="button__icon"
                    style="margin-left: 8px;"
                    v-html="getIconTemplate('icon-double-angle-right')"
                ></i>
            </Button>
        </template>
    </Dialog>
</template>

<script>
    import { icons } from "@/mixins";
    import AppDropdown from '@/components/AppDropdown';

    export default {
        name: "RestorePasswordDialog",
        mixins: [icons],
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            employeePositions: {
                type: Array,
                default: () => []
            },
            selectedEmployeePositionsId: {
                type: String,
                default: null
            },
            createUserSession: {
                type: Function
            }
        },
        data() {
            return {
                isCheckingPositionDialogVisible: false,
                localVisible: this.visible
            };
        },
        components: {
            AppDropdown,
        },
        methods: {
            setSelectedEmployeePositions(position) {
                this.$emit('update:selectedEmployeePositionsId', position.id);
            },
            closeDialog() {
                this.localVisible = false; // Изменяем локальную копию
                this.$emit('update:visible', false); // Сообщаем об изменении родителю
            }
        },
        watch: {
            visible(newVal) {
                this.localVisible = newVal;
            }
        }
    }
</script>

<style lang="sass" scoped>

</style>