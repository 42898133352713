import { lifetimeApi } from "@/api";

export default {
    state: {
        misIntegrationXlsxFile: null
    },
    actions: {
        async generateMisIntegrationXlsxFile({ dispatch, commit }, integrationData) {
            try {
                const token = await dispatch('getAccessToken');
                if (token) {
                    const misIntegrationXlsxFile = await lifetimeApi.generateMisIntegrationXlsxFile(integrationData, token);
                    commit("updateMisIntegrationXlsxFile", misIntegrationXlsxFile);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return false;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    mutations: {
        updateMisIntegrationXlsxFile(state, misIntegrationXlsxFile) {
            state.misIntegrationXlsxFile = misIntegrationXlsxFile;
        },
    },
    getters: {
        getMisIntegrationXlsxFile(state) {
            return state.misIntegrationXlsxFile;
        },
    },
}