import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Permission } from "./directives";
import moment from "moment";
import { vMaska } from "maska";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import PrimeVue from 'primevue/config';
import Calendar from 'primevue/calendar';
import AutoComplete from 'primevue/autocomplete';
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';
import Galleria from 'primevue/galleria';
import DataTable from 'primevue/datatable';
import TreeTable from 'primevue/treetable';
import Column from 'primevue/column';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Textarea from 'primevue/textarea';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Password from 'primevue/password';
import InputSwitch from 'primevue/inputswitch';
import Sidebar from 'primevue/sidebar';
import Chart from 'primevue/chart';
import ProgressBar from 'primevue/progressbar';



import Badge from 'primevue/badge';
import VCalendar from 'v-calendar';

import 'v-calendar/style.css';
import "primevue/resources/themes/bootstrap4-light-purple/theme.css";
import "primevue/resources/primevue.min.css";


import AdminLayout from "./layouts/AdminLayout.vue";
import PageLayout from "./layouts/PageLayout.vue";

library.add(fas);

createApp(App)
    .use(store)
    .use(router)
    .use(moment)
    .use(ToastService)
    .use(PrimeVue, {
        locale: {
            firstDayOfWeek: 1,
            dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
            dayNamesShort: ["Вс","Пн","Вт","Ср","Чт","Пт","Сб"],
            dayNamesMin: ["Вс","Пн","Вт","Ср","Чт","Пт","Сб"],
            monthNames: ["Январь","Февраль","Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь"],
            monthNamesShort: ["Янв", "Фев", "Март", "Апр", "Май", "Июнь","Июль", "Авг", "Сен", "Окт", "Ноя", "Дек"],
            today: 'Сейчас',
            clear: "Очистить",
            dateFormat: 'mm/dd/yy',
            fileSizeTypes: ['Bytes', 'KB', 'MB', 'GB', 'TB']
     }
    })
    .use(VCalendar, {})
    .directive("maska", vMaska)
    .directive('tooltip', Tooltip)
    .directive('permission', Permission)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component("page-layout", PageLayout)
    .component("admin-layout", AdminLayout)
    .component('Calendar', Calendar)
    .component('AutoComplete', AutoComplete)
    .component('Dropdown', Dropdown)
    .component('MultiSelect', MultiSelect)
    .component('FileUpload', FileUpload)
    .component('Button', Button)
    .component('Galleria', Galleria)
    .component('DataTable', DataTable)
    .component('TreeTable', TreeTable)
    .component('Column', Column)
    .component('Toast', Toast)
    .component('Dialog', Dialog)
    .component('Checkbox', Checkbox)
    .component('InputText', InputText)
    .component('InputMask', InputMask)
    .component('Textarea', Textarea)
    .component('Accordion', Accordion)
    .component('AccordionTab', AccordionTab)
    .component('Badge', Badge)
    .component('Password', Password)
    .component('InputSwitch', InputSwitch)
    .component('Sidebar', Sidebar)
    .component('Chart', Chart)
    .component('ProgressBar', ProgressBar)
    .mount("#app")