<template>
    <div class="todo__block_btn">
        <span
            v-show="descriptionButtonShowStatus"
            class="todo__action todo__action_description"
            @click.stop="descriptionAction(argumentForAction)"
        >
            <font-awesome-icon icon="ellipsis-h" />
        </span>
        <span
            v-show="!updateButtonShowStatus"
            v-permission="updateButtonPermission"
            class="todo__action todo__action_update"
            @click.stop="editAction(argumentForAction)"
        >
            <i
                v-html="getIconTemplate('icon-edit')"
            ></i>
        </span>
        <span
            v-show="!deleteButtonShowStatus"
            v-permission="deleteButtonPermission"
            class="todo__action todo__action_delete"
            @click.stop="deleteAction(argumentForAction)"
        >
            <i
                v-html="getIconTemplate('icon-delete')"
            ></i>
        </span>
    </div>
</template>

<script>
import { icons } from "@/mixins";

export default {
    name: "AppTableButtonBlock",
    mixins: [icons],
    props: {
        descriptionButtonShowStatus: Boolean,
        updateButtonShowStatus: Boolean,
        deleteButtonShowStatus: Boolean,
        descriptionAction: Function,
        editAction: Function,
        deleteAction: Function,
        argumentForAction: [Number, String, Function],
        deleteButtonPermission: String,
        updateButtonPermission: String,
    }
};
</script>
