<template>
    <Dialog
        v-model:visible="getIntegrationDialogVisibility"
        modal
        header="Header"
        class="dialog"
    >
        <template #header>
            <div class="dialog__header">
                Подпись и отправка СЭМД
            </div>
        </template>
        <template #closeicon>
            <div style="display: none"></div>
        </template>
        <div class="dialog__body" style="display: flex; flex-direction: column; gap: 20px;">
            <div
                v-if="$route.name !== 'lifetimeCaseById' && $route.name !== 'semdIntegration'" 
                class="integration__documents"
            >
                <p class="integration__documents-count">
                    В очередь на выгрузку будет отправлено {{ getLifetimeCasesChecked.length }} документа(-ов). Вы действительно хотите отправить документы на выгрузку?
                </p>
            </div>
            <div class="integration__sertificates" style="display: flex; gap: 12px; width: 100%;">
                <div class="integration__sertificate" style="width: 100%;">
                    <AppCombobox
                        :comboboxLabel="'Сотрудник на подпись'"
                        :getSuggestionList="getSuggestionList"
                        :getMoreSuggestions="getMoreSuggestions"
                        :optionLabel="'full_value'"
                        :comboboxId="'selectedEmployee'"
                        :defaultValue="checkSignatoryEmployee"
                        placeholder="Выбрать из списка"
                        isRequiredStyle
                        @changeSelectedItem="(e) => selectedEmployee = e"
                    >
                        <template #optionitem="{data}">
                            <div style="font-size: 12px; font-weight: 500; color: #6A3F97;">
                                {{ data.option.full_name }}
                            </div>
                            <div style="font-size: 12px; font-weight: 500;">
                                {{ data.option.position_name }}
                            </div>
                        </template>
                    </AppCombobox>
                </div>
                <Button
                    v-if="$route.name === 'lifetimeCaseById'"
                    style="margin-top: 18px; min-width: auto;"
                    class="button__blue"
                    label="Сформировать"
                    :disabled="!selectedEmployee"
                    :loading="isSemdLoading"
                    @click="createSemdById()"
                >
                    <template #icon>
                        <i
                            class="button__icon"
                            v-html="getIconTemplate('icon-file-add')"
                        ></i>
                    </template>
                </Button>
            </div>
            <div 
                v-if="$route.name === 'lifetimeCaseById' && (semdId || isSemdFailed)"
                class="integration__sertificates" 
                style="display: flex; 
                gap: 12px; 
                width: 100%;"
            >
                <div class="integration__sertificate" style="width: 100%;">
                    <AppFormActionConfirmation
                        :formConfirmationQuestionText="handleConfirmationQuestionText"
                        formConfirmationShowStatus
                        :formConfirmationTheme="handleConfirmationTheme"
                        :formConfirmationIcon="isSemdFailed ? 'icon-error' : 'icon-success'"
                    />
                </div>
            </div>
            <div
                v-if="($route.name === 'lifetimeCaseById' && semdId) || $route.name === 'semdIntegration'" 
                class="integration__sertificates" 
                style="display: flex; gap: 12px; flex-direction: column;"
            >
                <div class="integration__sertificate" style="width: 100%;">
                    <AppDropdown
                        :selectOptionList="certificates"
                        :dropdownLabel="'Электронная подпись сотрудника'"
                        dropdownPlaceholder="Выбрать из списка"
                        :optionLabel="'name'"
                        :disableStatus="!certificates.length"
                        isRequiredStyle
                        @updateDropdownItem="(e) => selectedEmployeeSignature = e.thumbprint"
                    />
                </div>
                <div class="integration__sertificate" style="width: 100%;">
                    <AppDropdown
                        :selectOptionList="certificates"
                        :dropdownLabel="'Электронная подпись организации'"
                        dropdownPlaceholder="Выбрать из списка"
                        :optionLabel="'name'"
                        :disableStatus="!certificates.length"
                        isRequiredStyle
                        @updateDropdownItem="(e) => selectedOrganizationSignature = e.thumbprint"
                    />
                </div>
            </div>
        </div>
        <template #footer>
            <Button
                :class="$route.name === 'lifetimeCaseById' || $route.name === 'semdIntegration' ? 'button__blue_border' : 'button__purple_border'"
                :label="$route.name === 'lifetimeCaseById' || $route.name === 'semdIntegration' ? 'Отменить' : 'Нет'"
                @click="closeDialog()"
            />
            <Button
                v-if="$route.name === 'lifetimeCaseById' || $route.name === 'semdIntegration'"
                v-permission="'sign_and_send_semd_doc'"
                class="button__blue"
                label="Подписать и отправить"
                :disabled="!selectedEmployeeSignature || !selectedOrganizationSignature"
                @click="signAndSend()"
            >
                <template #icon>
                    <i
                        class="button__icon"
                        v-html="getIconTemplate('icon-file-check')"
                    ></i>
                </template>
            </Button>
            <Button
                v-else
                class="button__purple"
                label="Да"
                :disabled="!selectedEmployee"
                @click="createSemd()"
            />
        </template>
    </Dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { icons } from "@/mixins";
import { api } from "@/api";
import { getUserCertificates, createDetachedSignature, createHash } from 'crypto-pro';
import AppCombobox from "@/components/AppCombobox.vue";
import AppDropdown from "@/components/AppDropdown.vue";
import AppFormActionConfirmation from "@/components/AppFormActionConfirmation.vue";

export default {
    name: "IntegrationSemdDialog",
    mixins: [icons],
    data() {
        return {
            certificates: [], // Список сертификатов
            selectedEmployee: null, // Выбранный сотрудник на подпись
            selectedEmployeeSignature: null,
            selectedOrganizationSignature: null,
            semdId: null,
            semdData: null,
            page: 1,
            pageSize: 50,
            totalPages: null,
            isSemdFailed: false,
            fileContent: '',
            employeeSignature: null,
            organizationSignature: null,
            isSemdLoading: false
        }
    },
    components: {
        AppCombobox,
        AppDropdown,
        AppFormActionConfirmation
    },
    computed: {
        ...mapGetters([
            "getIntegrationDialogVisibility",
            "getLifetimeCasesChecked",
            "getCurrentEmployeeDepartment",
            "getSelectedSemds",
            "getIntegrationIds",
            "getSemdIntegrationById"
        ]),
        handleConfirmationQuestionText() {
            return this.isSemdFailed ? "Ошибка формирования документа" : "Документ успешно создан."
        },
        handleConfirmationTheme() {
            return this.isSemdFailed ? "red" : "green"
        },
        checkSignatoryEmployee() {
            if (this.getSelectedSemds && this.getSelectedSemds.length) {
                return {
                    id: this.getSelectedSemds[0].signatory_full?.id,
                    full_name: this.getSelectedSemds[0].signatory_full?.full_name,
                    position_name: this.getSelectedSemds[0].signatory_full?.position_name,
                    position_id: this.getSelectedSemds[0].signatory_full?.position_id,
                    full_value: `${this.getSelectedSemds[0].signatory_full?.full_name}, ${this.getSelectedSemds[0].signatory_full?.position_name}`
                };
            }
            return null;
        }  
    },
    methods: {
        ...mapActions([
            "updateIntegrationDialogVisibility",
            "getAccessToken",
            "getRedirectAuthAfterInvalidRefreshTokenAction",
            "createSemdIntegration",
            "signSemdIntegration",
            "fetchSemdIntegrationById",
            "addToastMessage"
        ]),
        async getCertificates() {
            try {
                const certs = await getUserCertificates();
                this.certificates = certs;
            } catch (error) {
                this.certificateError = error.message;
            }
        },
        async createSignature() {
            try {
                let response;
                if (this.$route.name === 'lifetimeCaseById') {
                    response = await fetch(this.semdData?.object_id);
                } else {
                    response = await fetch(this.getSelectedSemds[0]?.object_id);
                }

                if (!response.ok) {
                    throw new Error('Ошибка загрузки файла.');
                }
                this.fileContent = await response.text();

                // Создаем хеш для файла
                const hash = await createHash(this.fileContent);

                
                // Первая подпись
                const firstSignature = await createDetachedSignature(this.selectedEmployeeSignature, hash);
                this.employeeSignature = firstSignature;

                // Вторая подпись на основе первой
                const secondSignature = await createDetachedSignature(this.selectedOrganizationSignature, hash);
                this.organizationSignature = secondSignature;
            } catch (error) {
                this.addToastMessage({code: 400, message: error.message});
            }
        },
        async getSuggestionList(searchValue) {
            this.page = 1;
            try {
                const token = await this.getAccessToken();
                if (token === undefined) {
                    this.getRedirectAuthAfterInvalidRefreshTokenAction();
                    return;
                }
                const queryParams = {
                    active: true,
                    page: this.page,
                    page_size: this.pageSize,
                    search: searchValue,
                    own_non_retired_employee: true,
                    employee_own_department: true
                };
                const responsibleStaffMembers = await api.getSettingsReferenceEmployees(queryParams, token);
                this.totalPages = responsibleStaffMembers.total_pages;
                const profilesArray = responsibleStaffMembers.results
                    // Преобразуем каждый объект сотрудника
                    .flatMap(person => 
                        // Фильтруем позиции, оставляя только те, которые соответствуют нужному департаменту
                        person.employee_positions
                            .filter(position => position.department.id === this.getCurrentEmployeeDepartment.id)
                            .map(position => ({
                                id: person.id, // ID сотрудника
                                full_name: person.full_name, // Полное имя сотрудника
                                position_name: position.position.name, // Название должности
                                position_id: position.id, // ID должности
                                full_value: `${person.full_name}, ${position.position.name}`,
                                organization: position.department.organization?.full_name,
                                department: position.department.full_name
                            }))
                    );
                return profilesArray;
            } catch (error) {
                console.log(error);
            }
        },
        async getMoreSuggestions(searchValue) {
            this.page++;
            if (this.page <= this.totalPages) {
                try {
                    const token = await this.getAccessToken();
                    if (token === undefined) {
                        this.getRedirectAuthAfterInvalidRefreshTokenAction();
                        return;
                    }
                    const queryParams = {
                        active: true,
                        page: this.page,
                        page_size: this.pageSize,
                        search: searchValue,
                        own_non_retired_employee: true,
                        employee_own_department: true
                    };
                    const responsibleStaffMembers = await api.getSettingsReferenceEmployees(queryParams, token);
                    const profilesArray = responsibleStaffMembers.results
                        // Преобразуем каждый объект сотрудника
                        .flatMap(person => 
                            // Фильтруем позиции, оставляя только те, которые соответствуют нужному департаменту
                            person.employee_positions
                                .filter(position => position.department.id === this.getCurrentEmployeeDepartment.id)
                                .map(position => ({
                                    id: person.id, // ID сотрудника
                                    full_name: person.full_name, // Полное имя сотрудника
                                    position_name: position.position.name, // Название должности
                                    position_id: position.id, // ID должности
                                    full_value: `${person.full_name}, ${position.position.name}`,
                                    organization: position.department.organization?.full_name,
                                    department: position.department.full_name
                                }))
                        );
                    return profilesArray;
                } catch (error) {
                    console.log(error);
                }
            }
        },
        closeDialog() {
            this.updateIntegrationDialogVisibility(false)
        },
        createSemdById() {
            this.isSemdLoading = true;
            const lifecase_ids = [this.$route.params.lifetimeCaseId];
            this.createSemdIntegration({
                lifecase_ids,
                employee_position_id: this.selectedEmployee.position_id
            })
            .then(() => {
                this.semdId = this.getIntegrationIds[0];
                if (this.semdId) {
                    setTimeout(() => {
                        this.fetchSemdIntegrationById(this.semdId)
                        .then(() => {
                            this.semdData = this.getSemdIntegrationById;
                            this.isSemdLoading = false;
                        })
                    }, 500);
                }
            })
            .catch(() => {
                this.isSemdFailed = true;
                this.isSemdLoading = false;
            });
        },
        createSemd() {
            const lifecase_ids = this.getLifetimeCasesChecked.map(lifecase => lifecase.id);
            this.createSemdIntegration({
                lifecase_ids,
                employee_position_id: this.selectedEmployee.position_id
            }).then(() => {
                this.updateIntegrationDialogVisibility(false);
            });
        },
        signAndSend() {
            this.createSignature()
            .then(() => {
                if (this.employeeSignature && this.organizationSignature) {
                    let semdIds;
                    if (this.$route.name === 'lifetimeCaseById') {
                        semdIds = [this.semdId];
                    } else if (this.$route.name === 'semdIntegration') {
                        semdIds = this.getSelectedSemds.map(semd => semd.id);
                    }
                    const dataForSemdSign = {
                        semd_ids: semdIds,
                        first_employee_sign: this.employeeSignature,
                        organization_sign: this.organizationSignature,
                    };
                    this.signSemdIntegration(dataForSemdSign).then(() => {
                        this.updateIntegrationDialogVisibility(false);
                    });
                }
            })
        }
    },
    mounted() {
        if ((this.$route.name === 'lifetimeCaseById' || this.$route.name === 'semdIntegration') && this.getIntegrationDialogVisibility) {
            this.getCertificates();
        }
    }
}
</script>