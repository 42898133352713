<template>
  <div
    v-if="getCurrentUserPermissions !== null"
    class="container"
  >
    <div class="admin">
      <div class="main">
        <div
          class="main__left"
          :class="{ main__left_mobile: getInterfaceMenuShowBtn }"
        >
          <TheSidebar />
        </div>
        <div
          class="main__right print"
          :class="{ main__right_mobile: getInterfaceMenuShowBtn }"
        >
          <!-- <PrintContainer /> -->
          <AppHeader />
          <slot />
          <AppFooter />
        </div>
      </div>
    </div>

    <teleport to="#app">
      <AcceptPrintDialog />
      <IntegrationSemdDialog
        v-if="getIntegrationDialogVisibility"
      />
    </teleport>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TheSidebar from "@/components/TheSidebar.vue";
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";
import AcceptPrintDialog from "@/components/AcceptPrintDialog.vue";
import IntegrationSemdDialog from "@/components/IntegrationSemdDialog.vue";

export default {
    name: "AdminLayout",
    components: {
        TheSidebar,
        AppFooter,
        AppHeader,
        AcceptPrintDialog,
        IntegrationSemdDialog
    },
    computed: {
        ...mapGetters([
            "getInterfaceMenuShowBtn",
            "getProfileData",
            "getCurrentUserPermissions",
            "getIntegrationDialogVisibility"
        ])
    },
    async beforeMount() {
      if (this.getProfileData.id === undefined) {
        // this.fetchProfileData();
      }
    },
    methods: {
        ...mapActions([
            "fetchProfileData",
        ]),
    },
};
</script>

<style lang="sass">
@import "@/assets/css/_vars.sass"
.admin
    width: 100%
    // background: $backgroundMainContent
    height: 100vh
    letter-spacing: 0.02em
    .main
        &__left
            width: 240px
            height: calc( 100vh - 16px )
            overflow: hidden
            position: fixed
            transition: all 0.2s ease-in-out
            background-color: $adminBackgroundMenuPanelColor
            z-index: 1001
            margin: 8px 0
            border-radius: 0px 12px 12px 0px
        &__right
            margin-left: 240px
            transition: all 0.2s ease-in-out
            .content
                width: 100%
                min-width: 800px
                padding: 0px 20px 0px 16px
                margin: 0 auto
                &__header
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: flex-start
                    margin-bottom: 30px
                    &_title
                        font-size: 25px
                        color: $adminMainTextContentColor
        &__left_mobile
            width: 96px
            overflow: visible
            .main__left_scroll
              padding: 24px 16px
            .header__left
              justify-content: center
              padding-left: 7px
              .chevron__left
                padding-right: 5px
            //Закомментированный код отображает развернутое меню при наведении на свернутую панель меню
            // &:hover
            //     width: 230px
            // .main__left_scroll:hover > .sidebar__container .sidebar__container_up .sidebar .sidebar__menu .sidebar__menu_list .sidebar__menu_link span,
            // .main__left_scroll:hover > .sidebar__container .sidebar__container_up .sidebar .sidebar__menu .sidebar__submenu_list .sidebar__submenu_link,
            // .main__left_scroll:hover > .sidebar__container .sidebar__container_up .header__left .chevron__left
            //     opacity: 1
            // .main__left_scroll:hover > .sidebar__container .sidebar__container_up .header__left .header__left_logo img
            //     display: flex
            // .hidden__submenu,
            // .main__left_scroll:hover > .sidebar__container .sidebar__container_up .header__left .mob_logo img
            //     display: none
            // .main__left_scroll:hover .hidden__submenu
            //     display: block
            // .sidebar__menu_link span,
            // .sidebar__submenu_link,
            // .sidebar__container .sidebar__container_up .header__left .chevron__left
            //     opacity: 0
            //Закомментированный код отображает развернутое меню при наведении на свернутую панель меню
            .sidebar__menu_link span, //закомментировать, если раскомментирован код отвечающий за ховер-эффект показа развернутого меню
            .sidebar__submenu_link, //закомментировать, если раскомментирован код отвечающий за ховер-эффект показа развернутого меню
            .sidebar__container .sidebar__container_up .header__left .header__left_logo img
                display: none
            .sidebar__container .sidebar__container_up .header__left .mob_logo img,
            .main__left_scroll .header__left .mob_logo
                display: flex
            .sidebar__menu_icon
                opacity: 1
        &__right_mobile
            margin-left: 95px
@media print
    .header, .main__left, .content__header_title
        display: none
    .container
        .main
            padding-top: 0
            letter-spacing: 0em
        .main__right
            margin: 0
            .content
                padding: 0
                margin: 0
            .content__header
                margin-bottom: 0
    .print
        border: none
        background-color: none
</style>