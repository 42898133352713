<template>
    <div class="combobox__wrapper">
        <p class="field__label">
            {{ comboboxLabel }}
            <span
                v-if="isRequiredStyle"
                class="input__field_required"
            >*</span>
        </p>
        <AutoComplete
            :ref="comboboxId"
            v-model="selectedItem"
            :class="{ 'p-invalid': chechInvalidStatus }"
            :suggestions="filteredItems"
            inputClass="combobox__input"
            :inputId="comboboxId"
            :panelClass="{
                'combobox__panel': filteredItems.length,
            }"
            :panelStyle="{
                width: comboboxWidth + 'px'
            }"
            :pt="{
                loadingIcon: {class: 'combobox__loading'},
                panel: {style: !filteredItems.length ? 'display: none' : 'display: block'},
                label: {style: 'padding: 0px'}
            }"
            dropdownClass="combobox__button"
            :placeholder="placeholder"
            :optionLabel="optionLabel"
            :disabled="isDisable"
            :optionDisabled="optionDisabled"
            :completeOnFocus="!selectedItem ? true : false"
            :forceSelection="!manualInput"
            dropdown
            @dropdownClick="handleDropdownClick"
            @complete="search"
            @show="loadMore"
        >
            <template v-slot:dropdownicon>
                <svg 
                    v-if="!selectedItem"
                    xmlns="http://www.w3.org/2000/svg" 
                    width="16" 
                    height="16" 
                    viewBox="0 0 16 16" 
                    fill="none"
                >
                    <path
                        d="M12.7074 5.23599C12.6146 5.14306 12.5043 5.06935 12.3829 5.01905C12.2615 4.96876 12.1315 4.94287 12.0001 4.94287C11.8687 4.94287 11.7386 4.96876 11.6173 5.01905C11.4959 5.06935 11.3856 5.14306 11.2928 5.23599L8.23542 8.29266C8.17291 8.35515 8.08814 8.39025 7.99975 8.39025C7.91136 8.39025 7.82659 8.35515 7.76408 8.29266L4.70742 5.23599C4.51991 5.04839 4.26556 4.94297 4.00032 4.94291C3.73508 4.94284 3.48068 5.04815 3.29308 5.23566C3.10549 5.42317 3.00006 5.67752 3 5.94276C2.99994 6.20799 3.10524 6.46239 3.29275 6.64999L6.35008 9.70732C6.56676 9.92402 6.82399 10.0959 7.1071 10.2132C7.39021 10.3305 7.69365 10.3908 8.00008 10.3908C8.30652 10.3908 8.60996 10.3305 8.89307 10.2132C9.17618 10.0959 9.43341 9.92402 9.65008 9.70732L12.7074 6.64999C12.8949 6.46246 13.0002 6.20816 13.0002 5.94299C13.0002 5.67783 12.8949 5.42352 12.7074 5.23599Z"
                        fill="#6A3F97"
                    />
                </svg>
                <svg 
                    v-else
                    xmlns="http://www.w3.org/2000/svg" 
                    width="16" 
                    height="16" 
                    viewBox="0 0 16 16" 
                    fill="none"
                >
                    <path 
                        d="M11.9999 3.99988C11.8749 3.8749 11.7053 3.80469 11.5285 3.80469C11.3518 3.80469 11.1822 3.8749 11.0572 3.99988L7.99988 7.05721L4.94255 3.99988C4.81753 3.8749 4.64799 3.80469 4.47121 3.80469C4.29444 3.80469 4.1249 3.8749 3.99988 3.99988C3.8749 4.1249 3.80469 4.29444 3.80469 4.47121C3.80469 4.64799 3.8749 4.81753 3.99988 4.94255L7.05721 7.99988L3.99988 11.0572C3.8749 11.1822 3.80469 11.3518 3.80469 11.5285C3.80469 11.7053 3.8749 11.8749 3.99988 11.9999C4.1249 12.1249 4.29444 12.1951 4.47121 12.1951C4.64799 12.1951 4.81753 12.1249 4.94255 11.9999L7.99988 8.94254L11.0572 11.9999C11.1822 12.1249 11.3518 12.1951 11.5285 12.1951C11.7053 12.1951 11.8749 12.1249 11.9999 11.9999C12.1249 11.8749 12.1951 11.7053 12.1951 11.5285C12.1951 11.3518 12.1249 11.1822 11.9999 11.0572L8.94254 7.99988L11.9999 4.94255C12.1249 4.81753 12.1951 4.64799 12.1951 4.47121C12.1951 4.29444 12.1249 4.1249 11.9999 3.99988Z" 
                        fill="#6A3F97"
                    />
                </svg>
            </template>
            <template #option="slotProps">
                <slot name="optionitem" :data="slotProps"></slot>
            </template>
            <template v-slot:footer>
                <div ref="observer" class="observer"></div>
            </template>
        </AutoComplete>
        <div class="combobox__error-message">
            Не найдено
        </div>
    </div>
</template>

<script>
    import { throttle } from 'lodash';
    export default {
        name: "AppCombobox",
        emits: ["changeSelectedItem", "checkDisableStatus", "updateComboboxFocus"],
        props: {
            comboboxLabel: String,
            getSuggestionList: Function,
            getMoreSuggestions: Function,
            optionLabel: String,
            defaultStaffMember: String,
            defaultValue: [Object, String],
            isDisable: Boolean,
            optionDisabled: Function,
            manualInput: Boolean,
            comboboxId: String,
            isRequiredStyle: Boolean,
            placeholder: String,
            isFocus: Boolean,
        },
        data() {
            return {
                selectedItem: null,
                filteredItems: [],
                items: [],
                invalid: true,
                comboboxWidth: null,
                arrowButtonWidth: 37,
                searchString: null,
            };
        },
        computed: {
            chechInvalidStatus() {
                if (this.manualInput) {
                    return false;
                }
                return !this.invalid;
            }
        },
        methods: {
            async search(event) {
                if (!this.items?.length && !this.selectedItem) {
                    this.items = await this.getSuggestionList("");
                    this.filteredItems = this.items || [];
                    this.invalid = !!this.filteredItems.length
                } else {
                    this.searchString = event.query.trim();
                    if (event?.query.length) {
                        this.items = await this.getSuggestionList(this.searchString);
                        this.filteredItems = this.items || [];
                        this.invalid = !!this.filteredItems.length
                    } else {
                        this.items = await this.getSuggestionList("");
                        this.filteredItems = this.items || [];
                        this.invalid = !!this.filteredItems.length
                    }
                }
            },
            loadMore() {
                let options = {
                    root: document.querySelector(this.comboboxId),
                    rootMargin: "0px",
                    threshold: 1.0,
                };

                const callback = async (entries) => {
                    if (entries[0].isIntersecting && this.items.length) {
                    const newItems = await this.getMoreSuggestions(this.searchString);
                    if (newItems) {
                        this.items = [...this.items, ...newItems];
                        this.filteredItems = this.items || [];
                    }
                    }
                }

                let observer = new IntersectionObserver(throttle(callback, 1000), options);
                observer.observe(this.$refs.observer);
            },
            handleDropdownClick() {
                if (this.selectedItem) {
                    this.selectedItem = null;
                } else {
                    return;
                }
            }
        },
        watch: {
            selectedItem(newVal) {
                if (this.manualInput && newVal) {
                    this.$emit("changeSelectedItem", newVal);
                } else if (this.invalid && newVal && !this.manualInput) {
                    this.$emit("changeSelectedItem", newVal);
                } else if (!newVal) {
                    this.$emit("changeSelectedItem", newVal);
                    this.$emit("checkDisableStatus");
                }
            },
            invalid(newVal) {
                if (!newVal) {
                    this.$emit("checkDisableStatus")
                }
            },
            isDisable() {
                if (!this.defaultValue) {
                    this.selectedItem = null;
                }
            },
            defaultValue() {
                this.selectedItem = this.defaultValue;
            },
            isFocus(newValue) {
                if (newValue) {
                    const combobox = document.getElementById(this.comboboxId);
                    if (combobox) {
                        combobox.focus();
                    }
                    this.$emit("updateComboboxFocus", false);
                }
            },
        },
        async mounted() {
            this.comboboxWidth = document.getElementById(this.comboboxId)?.offsetWidth + this.arrowButtonWidth;
            if (this.defaultValue) {
                this.selectedItem = this.defaultValue;                
            }
        },
    }
</script>

<style lang="sass">
@import "@/assets/css/_vars.sass"

.p-autocomplete-panel
    z-index: 1200 !important
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight
    background: $whiteSmoke
    color: $mediumPurple
.p-autocomplete
    width: 100%
.combobox__wrapper
    width: 100%
    position: relative
    .combobox__input
        width: 100%
        border-radius: 4px 0 0 4px
        border: 1px solid $softGray
        border-right: none
        height: 38px
        padding: 12px 16px
        font-family: "Roboto Flex", sans-serif
        font-size: 14px
        font-weight: 500
        line-height: 20px
        letter-spacing: -0.14px
        color: #1D0D2D
        display: flex
        align-items: center
    .combobox__label
        font-size: 12px
        font-weight: 500
        line-height: 8px
        padding-bottom: 8px
        color: $darkPurple
    .combobox__button
        background: $white
        border-radius: 0px 4px 4px 0px
        border: 1px solid $softGray
        border-left: none
        width: 40px
        &:focus
            box-shadow: none
        .p-button-label
            padding: 0px !important
    .combobox__error-message
        display: none
    .p-focus
        .combobox__input
            box-shadow: none
            border: 1px solid $deepPurple
            border-right: none
        .combobox__button
            border: 1px solid $deepPurple
            border-left: none
    .p-invalid
        .combobox__input
            border: 1px solid $errorRed !important
            border-right: none !important
        .combobox__button
            border: 1px solid $errorRed !important
            border-left: none !important
    .p-disabled
        .combobox__input
            border: 1px solid $softGray !important
            border-right: none !important
            background-color: $inputDisabledBackgroundColor
        .combobox__button
            border: 1px solid $softGray !important
            border-left: none !important
            background-color: $inputDisabledBackgroundColor
            path
                fill: $dustyLavender !important
    .p-invalid + .combobox__error-message
        display: block
        position: absolute
        bottom: -6px
        left: 7px
        padding: 0 5px
        background: $white
    .combobox__error-message
        font-family: "Roboto Flex", sans-serif
        font-size: 10px
        font-weight: 500
        line-height: 12px
        color: $errorRed
.combobox__panel
    border-radius: 8px
    background: $white
    box-shadow: 0px 0px 8px 0px $whiteSmoke
    padding: 4px
    overflow-wrap: break-word
    .p-autocomplete-item
        font-family: "Roboto Flex", sans-serif
        font-size: 14px
        font-weight: 500
        line-height: 20px
        letter-spacing: -0.14px
        color: #1D0D2D
        padding: 12px 16px
        border-radius: 4px
        white-space: pre-wrap
        &:hover
            background: $whiteSmoke
            color: $mediumPurple
    .p-focus
        background: $whiteSmoke
        color: $royalPurple
.combobox__panel-empty
    display: none
.combobox__loading
    top: 10px !important
    right: 40px !important
</style>