<template>
    <label
        v-if="inputLabelTitle"
        :for="inputTextId"
        :class="'field__label'"
    >
        {{ inputLabelTitle }}
        <span
            v-if="isRequiredStyle"
            class="input__field_required"
        >*</span>
    </label>
    <span :class="[{'p-input-icon-right': inputIconLocationRight}, {'p-input-icon-left': inputIconLocationLeft}, 'icon__wrapper']">
        <i
            v-if="inputIcon"
            v-html="getIconTemplate(inputIcon)"
        ></i>
        <div class="input__container">
            <InputText
                v-if="inputMask === undefined && !isPasswordInput"
                :id="inputTextId"
                :ref="inputRef"
                v-model="inputValue"
                :type="inputTextType"
                :pt="getInputPTOptions"
                :placeholder="inputPlaceholder"
                :disabled="inputDisableStatus"
                :readonly="inputReadonlyStatus"
                :maxlength="inputMaxlength"
                min="0"
                :max="inputMaxValue"
                :aria-describedby="`${inputTextId}-help`"
                :autocomplete="'new-password'"
                @update:modelValue="onInput"
                @blur="onBlur"
                @focus="onFocus"
                @change="onChange"
                @keydown.enter.prevent="executeByPressEnterKeyAction"
                
            />
            <InputMask
                v-if="inputMask !== undefined && !isPasswordInput"
                :id="inputTextId"
                :ref="inputRef"
                v-model="inputValue"
                :pt="getInputPTOptions"
                :placeholder="inputPlaceholder"
                :disabled="inputDisableStatus"
                :readonly="inputReadonlyStatus"
                :mask="inputMask"
                :slotChar="inputMaskPlaceholder"
                unmask
                :autocomplete="'new-password'"
                @update:modelValue="onInput"
                @blur="onBlur"
                @focus="onFocus"
                @change="onChange"
                @keydown.enter.prevent="executeByPressEnterKeyAction"
            />
            <Password 
                v-if="isPasswordInput"
                :id="inputTextId"
                :ref="inputRef"
                v-model="inputValue"
                :pt="{
                    input: {
                        class: ['input__field', isInvalidInput ? 'input__field_border--color-error' : 'input__field_border--color-base'],
                    },
                    root: {
                        style: 'border-radius: 4px; width: 100%;'
                    },
                    showIcon: {
                        class: 'input__password_icon'
                    },
                    hideIcon: {
                        class: 'input__password_icon'
                    },
                    panel: {
                        style: 'display: none;'
                    }
                }"
                :placeholder="inputPlaceholder"
                :disabled="inputDisableStatus"
                :readonly="inputReadonlyStatus"
                :autocomplete="'new-password'"
                toggleMask
                @update:modelValue="onInput"
                @blur="onBlur"
                @focus="onFocus"
                @change="onChange"
                @keydown.enter.prevent="executeByPressEnterKeyAction" 
            />
            <small
                v-if="isInvalidInput"
                :id="`${inputTextId}-help`"
                class="input__text_help"
                :class="[{'input__text_help--text-error': isInvalidInput}]"
            >
                {{ inputHelpText }}
            </small>
        </div>
    </span>
</template>

<script>

import { icons } from "@/mixins";
export default {
    name: "AppInputText",
    mixins: [icons],
    emits: ['onInput', 'onBlur', 'onFocus', 'onChange'],
    props: {
        inputTextId: {
            type: String,
            required: true
        },
        inputTextType: {
            type: String,
            default: "text"
        },
        inputRef: String,
        inputLabelTitle: String,
        inputPlaceholder: String,
        inputHelpText: String,
        inputDisableStatus: Boolean,
        inputReadonlyStatus: Boolean,
        vModelInputText: [String, Number],
        isInvalidInput: Boolean,
        isRequiredStyle: Boolean,
        inputMask: String,
        inputMaskPlaceholder: String,
        inputIcon: String,
        inputIconLocationRight: Boolean,
        inputIconLocationLeft: Boolean,
        inputMaxlength: Number,
        inputMaxValue: Number,
        executeByPressEnterKeyAction: Function,
        inputClasses: Array,
        isPasswordInput: Boolean
    },
    data() {
        return {
            inputValue: this.vModelInputText,
        }
    },
    computed: {
        getInputPTOptions() {
            return {
                root: {
                    class: [
                        this.inputClasses,
                        'input__field',
                        {
                            'input__field_border--color-error': this.isInvalidInput,
                            'input__field_border--color-base': !this.isInvalidInput,
                        },

                    ]
                },
            }
        }
    },
    methods: {
        onInput(value) {
            this.$emit('onInput', value);
        },
        onBlur(event) {
            this.$emit('onBlur', event);
        },
        onFocus(event) {
            this.$emit('onFocus', event);
        },
        onChange(event) {
            this.$emit('onChange', event);
        },
    },
    watch: {
        vModelInputText(value) {
            this.inputValue = value;
        }
    },
}
</script>

<style lang="sass">
@import "@/assets/css/_vars.sass"

.input__container
    position: relative
    width: 100%
.input__field
    display: flex
    width: 100%
    height: 38px
    padding: 12px 16px 12px 16px
    font-size: 13px
    font-weight: 400
    line-height: 1.5
    background-clip: padding-box
    background-color: $formInputBackgroundColor
    border-radius: 4px
    color: $textPrimaryBlack
    &:disabled
        background-color: $surfacePrimary
        border: 1px solid $inputDisabledBorderColor
        color: $textPrimaryBlack
    &_border--color-error
        border: 1px solid $errorInputBorderColor
    &_border--color-base
        border: 1px solid $formInputBorderColor
    &:focus:not(&_border--color-error)
        border: 1px solid $formInputFocusBorderColor
    &:enabled:focus
        box-shadow: none
.icon__wrapper
    display: flex
    width: 100%
    & > i
        z-index: 3
        width: 16px
        height: 16px
        top: calc( 50% - 8px )
        svg
            width: 16px
            height: 16px
.input__text_help
    background-color: white
    position: absolute
    top: 35px
    left: 12px
    pointer-events: none
    font-size: 12px
    line-height: 4px
    padding: 0 4px
    &--text-success
        color: $notificationPatientSuccessBorderColor
    &--text-error
        color: $notificationErrorTextColor
.input__password_icon
    position: absolute
    width: 16px
    height: 16px
    top: calc( 50% - 8px )
    right: 16px
    color: $slateGray
    cursor: pointer
</style>