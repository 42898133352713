<template>
    <div class="main__left_scroll">
        <div class="sidebar__container">
            <div class="sidebar__container_up">
                <div class="header__left">
                    <div class="header__left_logo">
                        <router-link :to="lifetimeCasesLink">
                            <img src="../assets/img/logo-morfis-medium.svg" alt="logo" />
                        </router-link>
                    </div>
                    <div class="mob_logo">
                        <router-link :to="lifetimeCasesLink">
                            <img src="../assets/img/logo_min.png" alt="logo" />
                        </router-link>
                    </div>
                    <i
                        class="chevron__left"
                        :class="{ rotate: getInterfaceMenuShowBtn }"
                        @click.stop="showSidebar"
                        v-html="getIconTemplate('icon-sidebar-arrow-left')"
                    ></i>
                </div>
                <nav class="sidebar">
                    <ul
                        class="sidebar__menu"
                        :class="{ 'sidebar__menu-hidden': getInterfaceMenuShowBtn }"
                    >
                        <li
                            v-for="(menuItem, index) in getMenuItemsByPermission"
                            :key="`menuItem-${index}`"
                            :class="{ active: menuItemsState.activeItems[index] }"
                            style="position: relative; overflow: visible;"
                        >
                            <div
                                v-if="menuItem.submenuItems === undefined || menuItem.submenuItems.length === 0"
                                class="sidebar__menu_list"
                                @click.stop="setActiveMenuItem(index)"
                            >
                                <router-link :to="{ name: menuItem.routeName }" class="sidebar__menu_link">
                                    <i
                                        class="sidebar__menu_icon--flex"
                                        v-html="getIconTemplate(menuItem.itemIcon)"
                                    ></i>
                                    <span class="sidebar__menu_link--text">{{ menuItem.itemTitle }}</span>
                                </router-link>
                            </div>

                            <div
                                v-if="menuItem.submenuItems !== undefined && menuItem.submenuItems.length !== 0"
                                class="sidebar__menu_list"
                                :class="{ active__submenu_child: getActiveSubmenuItem(index) }"
                                @click.stop="showSubmenuList(index)"
                            >
                                <div class="sidebar__menu_link">
                                    <i
                                        class="sidebar__menu_icon--flex"
                                        v-html="getIconTemplate(menuItem.itemIcon)"
                                    ></i>
                                    <span class="sidebar__menu_link--text">{{ menuItem.itemTitle }}</span>
                                    <i
                                        class="chevron__down"
                                        :class="{ rotate: menuItemsState.showListItems[index] }"
                                        v-html="getIconTemplate('icon-sidebar-arrow-down')"
                                    ></i>
                                </div>
                            </div>
                            <Transition name="submenu">
                                <ul
                                    v-if="menuItem.submenuItems && menuItemsState.showListItems[index] && !getInterfaceMenuShowBtn"
                                    class="sidebar__submenu"
                                >
                                    <li
                                        v-for="(submenuItem, submenuIndex) in menuItem.submenuItems"
                                        :key="`submenuItem-${index}${submenuIndex}`"
                                        class="sidebar__submenu_list"
                                        :class="{ active__submenu: menuItemsState.activeItems?.[index]?.[submenuIndex] }"
                                        @click.stop="setActiveSubmenuItem(index, submenuIndex)"
                                    >
                                        <router-link
                                            :to="{ name: submenuItem.routeName }"
                                            class="sidebar__submenu_link"
                                        >
                                            <i
                                                v-if="menuItemsState.activeItems?.[index]?.[submenuIndex]"
                                                class="sidebar__icon--active"
                                                v-html="getIconTemplate('icon-circle-small-alt')"
                                            ></i>
                                            <i
                                                v-else
                                                class="sidebar__icon--not-active"
                                                v-html="getIconTemplate('icon-circle-small')"
                                            ></i>
                                            {{ submenuItem.subitemTitle }}
                                        </router-link>
                                    </li>
                                </ul>
                                <ul
                                    v-else-if="menuItem.submenuItems && menuItemsState.showListItems[index] && getInterfaceMenuShowBtn"
                                    class="sidebar__submenu_popup"
                                >
                                    <li
                                        v-for="(submenuItem, submenuIndex) in menuItem.submenuItems"
                                        :key="`submenuItem-${submenuIndex}`"
                                        class="sidebar__submenu_popup-item"
                                        :class="{ active__submenu: menuItemsState.activeItems?.[index]?.[submenuIndex] }"
                                        @click.stop="setActiveSubmenuItem(index, submenuIndex)"
                                    >
                                        <router-link
                                            :to="{ name: submenuItem.routeName }"
                                            class="sidebar__submenu_popup-link"
                                        >
                                            {{ submenuItem.subitemTitle }}
                                        </router-link>
                                    </li>
                                </ul>
                            </Transition>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { getLifetimeCasesLink } from "@/router";
import { mapActions, mapGetters } from "vuex";
import { icons } from "@/mixins";

export default {
    name: "TheSidebar",
    mixins: [icons],
    data() {
        return {
            lifetimeCasesLink: getLifetimeCasesLink(),
            menuItems: [
                {
                    itemTitle: "Прижизненные исследования",
                    itemIcon: "hand-holding-heart",
                    submenuItems: [
                        {
                            subitemTitle: "Журнал",
                            routeName: "lifetimeCases",
                            permission: "view_lifecase_journal"
                        },
                        {
                            subitemTitle: "Пробоподготовка",
                            routeName: "lifetimeCasesSamplePreparation",
                            permission: "view_sample_preparation_journal"
                        }
                    ]
                },
                {
                    itemTitle: "Интеграции",
                    itemIcon: "icon-integration",
                    submenuItems: [
                        {
                            subitemTitle: "Журнал СЭМД",
                            routeName: "semdIntegration",
                            permission: "view_semd_journal"
                        }
                    ]
                },
                {
                    itemTitle: "Аналитика",
                    itemIcon: "icon-metrics",
                    submenuItems: [
                        {
                            subitemTitle: "Метрики",
                            routeName: "metrics",
                            permission: "view_metrics"
                        }
                    ]
                },
                // {
                //     itemTitle: "Посмертные исследования",
                //     itemIcon: "hand-holding-medical",
                //     submenuItems: [
                //         {
                //             subitemTitle: "Журнал",
                //             routeName: "mortemCases",
                //             permission: "mortem"
                //         },
                //         {
                //             subitemTitle: "Пробоподготовка",
                //             routeName: "mortemCases",
                //             permission: "mortem"
                //         }
                //     ]
                // },
                // {
                //     itemTitle: "Архив",
                //     itemIcon: "archive",
                //     submenuItems: [
                //         {
                //             subitemTitle: "Прижизненный",
                //             routeName: "lifetimeCasesArchive",
                //             permission: "archive"
                //         },
                //         {
                //             subitemTitle: "Посмертный",
                //             routeName: "mortemCasesArchive",
                //             permission: "archive"
                //         }
                //     ]
                // },
                {
                    itemTitle: "Справочники",
                    itemIcon: "reference",
                    routeName: "reference",
                    permission: "view_dictionary_list"
                },
                {
                    itemTitle: "Настройки",
                    itemIcon: "setting",
                    routeName: "settings",
                    permission: "view_settings_list"
                }
            ],
            menuItemsState: {
                showListItems: {},
                activeItems: {}
            },
            routes: {}
        };
    },
    computed: {
        ...mapGetters([
            "getInterfaceMenuShowBtn",
            "getCurrentUserPermissions",
            "getProfileData"
        ]),
        getMenuItemsByPermission() {
            const filteredMenuItems = [];
            this.menuItems.forEach((menuItem, itemIndex) => {
                if (menuItem.submenuItems !== undefined && menuItem.submenuItems.length !== 0) {
                    const submenuItems = [];
                    menuItem.submenuItems.forEach((menuSubitem, subitemIndex) => {
                        if (this.getCurrentUserPermissions[menuSubitem.permission] || this.getProfileData.is_superuser) {
                            submenuItems.push(menuSubitem);
                            if (this.$route.path.includes(this.routes[menuSubitem.routeName])) { // Проверяем, относится ли текущий роут к текущему подпункту меню. (TODO Можно упростить, если использовать в роутере children)
                                this.setActiveSubmenuItem(itemIndex, subitemIndex);
                            }
                        }
                    });
                    if (submenuItems.length !== 0) {
                        menuItem.submenuItems = submenuItems;
                        filteredMenuItems.push(menuItem);
                    }
                }
                if (menuItem.submenuItems === undefined || menuItem.submenuItems.length === 0) {
                    if (this.getCurrentUserPermissions[menuItem.permission] || this.getProfileData.is_superuser) {
                        filteredMenuItems.push(menuItem);
                        if (this.$route.path.includes(this.routes[menuItem.routeName])) { // Проверяем, относится ли текущий роут к текущему пункту меню. (TODO Можно упростить, если использовать в роутере children)
                            this.setActiveMenuItem(itemIndex);
                        }
                    }
                }
            });
            return filteredMenuItems;
        },
    },
    methods: {
        ...mapActions([
            "updateMenuShowBtnStatus",
        ]),
        setActiveMenuItem(index) {
            this.menuItemsState.activeItems = {};
            this.menuItemsState.showListItems = {};
            this.menuItemsState.activeItems[index] = true;
        },
        showSubmenuList(index) {
            if (index === null) {
                this.menuItemsState.showListItems = {};
                return;
            }
            this.menuItemsState.showListItems = {[index]: !this.menuItemsState.showListItems[index]};
        },
        getActiveSubmenuItem(itemIndex) {
            if (this.menuItemsState.activeItems[itemIndex] === undefined) { // {activeItems: {}}
                return false;
            }
            if (typeof this.menuItemsState.activeItems[itemIndex] !== "object") { // {activeItems: {1: true}} | Пункт меню по индексом 1
                return false;
            }
            const activeItemsArray = Object.values(this.menuItemsState.activeItems[itemIndex]); // {activeItems: {1: {0: true}}} | Пункт меню по индексом 1 и подпункт под индексом 0 
            return activeItemsArray.some((item) => item);
        },
        setActiveSubmenuItem(itemIndex, subitemIndex) {
            this.menuItemsState.activeItems = {};
            if (!this.getInterfaceMenuShowBtn) {
                this.menuItemsState.showListItems = {[itemIndex]: true}; // перезаписываем состоянием текущего открытого пункта меню
            }
            this.menuItemsState.activeItems[itemIndex] = {};
            this.menuItemsState.activeItems[itemIndex][subitemIndex] = true;
        },
        closePopupSidebarSubmenu(event) {
            if (this.getInterfaceMenuShowBtn) {
                if (event.target.closest(".sidebar__menu_list") !== null) {
                    return;
                }
                this.showSubmenuList(null);
            }
        },
        showSidebar() {
            this.updateMenuShowBtnStatus();
            this.showSubmenuList(null);
        },
        checkAllIconsForStroke() {
            // Получаем все элементы <i> с классом 'sidebar__menu_icon--flex'
            const iconElements = document.querySelectorAll('.sidebar__menu_icon--flex');

            iconElements.forEach(iconElement => {
                const svg = iconElement.querySelector('svg');
                if (svg) {
                    const path = svg.querySelector('path');
                    if (path && path.hasAttribute('stroke')) {
                        // Если у path есть атрибут stroke, добавляем класс к <i>
                        iconElement.classList.add('has-stroke');
                    } else {
                        // Если атрибута stroke нет, убираем класс
                        iconElement.classList.remove('has-stroke');
                    }
                }
            });
        },
    },
    beforeMount() {
        this.$router.getRoutes().forEach((route) => {
            this.routes[route.name] = route.path; // Создаем объект из всех имеющихся роутов, который используем для отображения активного пункта меню при перезагрузке страницы
        });
    },
    mounted() {
        this.$nextTick(function() {
            window.addEventListener("click", this.closePopupSidebarSubmenu, true);
        });
        this.checkAllIconsForStroke();
    },
    updated() {
        this.checkAllIconsForStroke();
    },
    beforeUnmount() {
        window.removeEventListener("click", this.closePopupSidebarSubmenu, true);
    }
};
</script>

<style lang="sass">
@import "@/assets/css/_vars.sass"

.main__left_scroll
    position: relative
    overflow: visible
    padding: 24px 0 24px 16px
    width: 100%
    height: 100%
    .sidebar__container
        height: 100%
        display: flex
        flex-direction: column
        justify-content: space-between
        &_down
            display: flex
            flex-direction: column
            gap: 16px
            font-weight: 700
            font-size: 14px
            line-height: 17px
            padding-left: 24px
            width: fit-content
            a
                color: $sidebarMenuTextColor
                &:hover
                    color: $sidebarMenuTextHoverColor
                &:hover path
                    fill: $sidebarMenuTextHoverColor !important
        &_down-item
            display: flex
            gap: 12px
        .active__sidebar_down span
            color: $sidebarDownActiveTextColor
            &:hover
                color: $sidebarDownActiveTextColor
        .active__sidebar_down path
            fill: $sidebarDownActiveTextColor !important
        .active__sidebar_down:hover
            path
                fill: $sidebarDownActiveTextColor !important
    .header__left
        display: flex
        justify-content: space-between
        align-items: center
        background: $adminBackgroundMenuPanelColor
        transition: all 0.2s ease-in-out 0s
        padding-bottom: 40px
        padding-left: 11px
        .header__left_logo
            transition: all 0.4s ease
            img
                display: flex
                justify-content: center
                align-items: center
                width: 142px
                height: 24px
        .mob_logo
            transition: all 0.4s ease
            display: none
            justify-content: center
            align-items: center
            img
                width: 24px
                height: 24px
        .chevron__left
            display: flex
            justify-content: center
            align-items: center
            font-size: 14px
            color: $toggleBtnBGColor
            transition: all 0.2s ease-in-out 0s
            cursor: pointer
            padding-right: 16px
            &:hover
                color: $toggleBtnHoverColor
    .sidebar
        color: $sidebarMenuTextColor
        &__menu-hidden
            .sidebar__menu_list
                width: fit-content
                border-radius: 8px
            .sidebar__submenu
                display: none
            .chevron__down
                display: none
        &__submenu
            margin-top: 4px
            &_list
                align-items: center
                display: flex
                justify-content: flex-start
                transition: all 0.3s ease-in-out 0s
                width: 100%
                margin-bottom: 4px
            &_link
                display: flex
                justify-content: flex-start
                align-content: center
                font-size: 13px
                color: $sidebarSubmenuTextColor
                padding: 12px 16px 12px 20px
                border-top-left-radius: 8px
                border-bottom-left-radius: 8px
                width: 100%
                gap: 16px
                align-items: center
                &:hover
                    color: $sidebarSubmenuTextHoverColor
                    background-color: $sidebarSubmenuBackgroundHoverColor
                    & .sidebar__icon--not-active svg path
                        fill: $deepPurple
        &__submenu_popup
            width: 224px
            padding: 4px
            border-radius: 8px
            display: flex
            flex-direction: column
            gap: 4px
            position: absolute
            background: $sidebarSubmenuPopupBackgroundColor
            box-shadow: 0px 0px 8px 0px rgba(210, 207, 213, 0.75)
            top: -4px
            right: -231px
            &-item
                align-items: center
                display: flex
                justify-content: flex-start
                transition: all 0.3s ease-in-out 0s
                width: 100%
                border-radius: 4px
                &:hover
                    color: $sidebarSubmenuTextHoverColor
                    background: $sidebarSubmenuBackgroundHoverColor
            &-link
                display: flex
                justify-content: flex-start
                align-content: center
                font-size: 13px
                color: $sidebarSubmenuTextColor
                padding: 12px 16px
                width: 100%
                &:hover
                    color: $sidebarSubmenuTextHoverColor
                    background: $sidebarSubmenuBackgroundHoverColor
                    border-radius: 4px
        &__menu
            font-weight: 500
            font-size: 14px
            line-height: 17px
            &_list
                display: flex
                align-items: center
                border-top-left-radius: 8px
                border-bottom-left-radius: 8px
                justify-content: flex-start
                transition: all 0.2s ease-in-out 0s
                margin-bottom: 4px
                .sidebar__menu_link span
                    display: inline-block
                    width: 120px
                &:hover
                    color: $sidebarMenuTextHoverColor
                    background-color: $sidebarMenuBackgroundHoverColor
                &:hover span
                    color: $sidebarMenuTextHoverColor
                &:hover .sidebar__menu_link .has-stroke path
                &:hover .sidebar__menu_link .has-stroke path
                    // проставил important, т.к. в новой svg стили прописаны инлайн и у них большая приоритетность
                    fill: $sidebarMenuTextHoverColor !important
                    stroke: $sidebarMenuTextHoverColor !important
                &:hover .sidebar__menu_link path
                    // проставил important, т.к. в новой svg стили прописаны инлайн и у них большая приоритетность
                    fill: $sidebarMenuTextHoverColor !important
                &:hover .sidebar__menu_link path
                    // проставил important, т.к. в новой svg стили прописаны инлайн и у них большая приоритетность
                    fill: $sidebarMenuTextHoverColor !important
                a
                    color: $sidebarMenuTextColor
                    transition: all 0.2s ease-in-out 0s
                .sidebar__menu_icon
                    display: flex
                    width: 40px
                    justify-content: center
                    align-items: center
            .active__submenu_parent
                background-color: $sidebarMenuBackgroundActiveColor
                &:hover span
                        color: $sidebarActiveMenuTextColor
                .sidebar__menu_link
                    color: $sidebarActiveMenuTextColor
                    .has-stroke path
                        fill: $sidebarActiveMenuTextColor !important
                        stroke: $sidebarActiveMenuTextColor !important
                    path
                        fill: $sidebarActiveMenuTextColor !important
                    path
                        fill: $sidebarActiveMenuTextColor !important
            .active__submenu_child
                background-color: $sidebarMenuBackgroundActiveColor
                border-top-left-radius: 8px
                border-bottom-left-radius: 8px
                &:hover
                    color: $sidebarActiveMenuTextColor
                    background-color: $sidebarMenuBackgroundActiveColor
                    &:hover span
                        color: $sidebarActiveMenuTextColor
                    &:hover .sidebar__menu_link .has-stroke path
                        stroke: $sidebarActiveMenuTextColor !important
                        fill: $sidebarActiveMenuTextColor !important
                    &:hover .sidebar__menu_link path
                        fill: $sidebarActiveMenuTextColor !important
                .sidebar__menu_link
                    color: $sidebarActiveMenuTextColor
                    path
                        fill: $sidebarActiveMenuTextColor !important
                    .has-stroke path
                        fill: $sidebarActiveMenuTextColor !important
                        stroke: $sidebarActiveMenuTextColor !important
                a
                    color: $sidebarActiveMenuTextColor
            .active
                .sidebar__menu_list
                    background-color: $sidebarMenuBackgroundActiveColor
                    & span
                        color: $sidebarActiveMenuTextColor
                    & .has-stroke path
                        fill: $sidebarActiveMenuTextColor !important
                        stroke: $sidebarActiveMenuTextColor !important
                    & path
                        fill: $sidebarActiveMenuTextColor !important
            .active__submenu
                width: 100%
                background: $sidebarSubmenuBackgroundActiveColor
                border-top-left-radius: 4px
                border-bottom-left-radius: 4px
                a
                    color: $sidebarActiveSubmenuTextColor
                    &:hover
                        color: $sidebarActiveSubmenuTextColor
                        border-top-left-radius: 4px
                        border-bottom-left-radius: 4px
            .active__submenu_child
                color: $sidebarActiveMenuTextColor
            &_link
                display: flex
                justify-content: center
                align-content: center
                align-items: center
                position: relative
                padding: 12px 16px
                color: $sidebarMenuTextColor
                gap: 12px
                &:hover
                    color: $sidebarMenuTextHoverColor
    .summary__title
        width: 100%
        display: flex
        justify-content: space-between
.chevron__down
    // position: absolute
    // left: 184px
    display: flex
    font-size: 14px
    transition: all 0.2s ease-in-out 0s
.rotate
    transform: rotate(180deg)
.submenu-enter-active,
.submenu-leave-active 
    transition: opacity 0.1s ease
.submenu-enter-from,
.submenu-leave-to 
    opacity: 0
.sidebar__menu_link--text
    font-size: 12px
    font-style: normal
    font-weight: 500
    line-height: 18px
.sidebar__menu_icon--flex
    display: flex
.sidebar__icon--active svg, .sidebar__icon--not-active svg
    width: 24px
    height: 24px
    display: flex
.sidebar__icon--active svg path
    fill: $deepPurple
.sidebar__icon--not-active svg path
    fill: $darkPurple
</style>