import { lifetimeApi } from "@/api";

export default {
    state: {
        lifetimeMacroDescriptionPhotos: [],
        lifetimeMacroDescriptionCurrentPhotoIndex: 0,
        lifetimeMacroDescriptionVisibleSlides: [], // [0, 1, 2, 3, 4, 5]
        lifetimeMacroDescriptionVisibleLastSlides: [],
        lifetimeMacroDescriptionCountOfVisibleSlides: 6,
        lifetimeMacroDescriptionSliderLeftButtonStatus: false,
        lifetimeMacroDescriptionSliderRightButtonStatus: false,
        lifetimeMacroDescriptionHasPhotosToUploadStatus: false,
        isLifetimeMacroDescriptionLoading: false,
        lifetimeMacroDescriptionPhotosArchive: null,
        lifetimeMicropreparationPhotosArchive: null
    },
    actions: {
        async fetchLifetimeCasesMacroDescriptionPhotos({ dispatch }, lifetimeCaseId) {
            try {
                await dispatch("getAccessToken").then((token) => {
                    if (token === undefined) {
                        dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                        return;
                    }
                    dispatch("receiveLifetimeMacroDescriptionPhotos", [token, lifetimeCaseId]);
                });
            } catch (e) {
                console.error(e);
                return e;
            }
        },
        async receiveLifetimeMacroDescriptionPhotos({ commit }, [token, lifetimeCaseId]) {
            try {
                const lifetimeMacroDescriptionPhotos = await lifetimeApi.getLifetimeCasesMacroDescriptionPhotos(lifetimeCaseId, token);
                // commit("changeLifetimeMacroDescriptionHasPhotosToUploadStatus", false);
                // commit("clearLifetimeMacroDescriptionPhotos");
                commit("changeLifetimeMacroDescriptionPhotos", lifetimeMacroDescriptionPhotos);
            } catch (e) {
                console.error(e);
            }
        },
        async fetchLifetimePhotosArchive({ dispatch, commit }, lifetimeCaseId) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const lifetimeMacroDescriptionPhotosArchive = await lifetimeApi.getLifetimePhotosArchive(lifetimeCaseId, token);
                    commit("changeLifetimeMacroDescriptionPhotosArchive", lifetimeMacroDescriptionPhotosArchive.archive);
                }else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (e) {
                console.error(e);
            }
        },
        async fetchMicropreparationsPhotosArchive({ dispatch, commit }, micropreparationId) {
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const lifetimeMicropreparationPhotosArchive = await lifetimeApi.getMicropreparationPhotosArchive(micropreparationId, token);
                    commit("changeLifetimeMicropreparationPhotosArchive", lifetimeMicropreparationPhotosArchive.archive);
                }else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return;
                }
            } catch (e) {
                console.error(e);
            }
        },
        async addLifetimeCasesMacroDescriptionPhotos({ dispatch, commit }, [lifetimeCaseId, formData]) {
            commit("updateLifetimeMacroDescriptionPhotoLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const newImagesData = await lifetimeApi.addLifetimeCasesMacroDescriptionPhotos(lifetimeCaseId, token, formData);
                    commit("changeLifetimeMacroDescriptionPhotos", newImagesData);
                    commit("updateLifetimeMacroDescriptionPhotoLoader", false);
                    dispatch("updateFormCaseByIdTabsDisableBtnStatus", false);
                    dispatch("updateFormCaseByIdTabMacroDescriptionAllBtnStatus", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateLifetimeMacroDescriptionPhotoLoader", false);
                    return;
                }
            } catch (e) {
                console.error(e);
                commit("updateLifetimeMacroDescriptionPhotoLoader", false);
            }
        },
        async editLifetimeCasesMacroDescriptionPhoto({ commit }, [lifetimeCaseId, imageId, newPhotoData]) {
            try {
                const token = JSON.parse(localStorage.accessTokenData).access;
                const newData = await lifetimeApi.editLifetimeCasesMacroDescriptionPhoto(lifetimeCaseId, imageId, token, newPhotoData);
                console.log(newData);
                commit("editLifetimeMacroDescriptionPhoto", [imageId, newData.annotations]);
            } catch (e) {
                console.error(e);
            }
        },
        async deleteLifetimeCasesMacroDescriptionPhoto({ commit }, [lifetimeCaseId, imageId]) {
            try {
                const token = JSON.parse(localStorage.accessTokenData).access;
                await lifetimeApi.deleteLifetimeCasesMacroDescriptionPhoto(lifetimeCaseId, imageId, token);
                commit("deleteLifetimeMacroDescriptionPhoto", imageId);
            } catch (e) {
                console.error(e);
            }
        },
        updateLifetimeMacroDescriptionPhotos({ commit }, [lifetimeMacroDescriptionPhotos, status]) {
            switch (status) {
                case "delete":
                    commit("deleteLifetimeMacroDescriptionPhoto", lifetimeMacroDescriptionPhotos);
                    return;
                case "clear":
                    commit("clearLifetimeMacroDescriptionPhotos");
                    return;
                default:
                    commit("changeLifetimeMacroDescriptionPhotos", lifetimeMacroDescriptionPhotos);
                    return;
            }
        },
        updateLifetimeMacroDescriptionCurrentPhotoIndex({ commit }, lifetimeMacroDescriptionCurrentPhotoIndex) {
            commit("changeLifetimeMacroDescriptionCurrentPhotoIndex", lifetimeMacroDescriptionCurrentPhotoIndex);
        },
        updateLifetimeMacroDescriptionVisibleSlides({ commit }, [lifetimeMacroDescriptionVisibleEntity, status]) {
            switch (status) {
                case "addLast":
                    commit("addLastLifetimeMacroDescriptionVisibleSlides", lifetimeMacroDescriptionVisibleEntity);
                    return;
                case "addFirst":
                    commit("addFirstLifetimeMacroDescriptionVisibleSlides", lifetimeMacroDescriptionVisibleEntity);
                    return;
                case "removeFirst":
                    commit("removeFirstLifetimeMacroDescriptionVisibleSlides");
                    return;
                case "removeLast":
                    commit("removeLastLifetimeMacroDescriptionVisibleSlides");
                    return;
                default:
                    commit("changeLifetimeMacroDescriptionVisibleSlides", lifetimeMacroDescriptionVisibleEntity);
                    return;
            }
        },
        updateLifetimeMacroDescriptionVisibleLastSlides({ commit }, [lifetimeMacroDescriptionVisibleLastEntity, status]) {
            switch (status) {
                case "addFirst":
                    commit("addFirstLifetimeMacroDescriptionVisibleLastSlides", lifetimeMacroDescriptionVisibleLastEntity);
                    return;
                case "removeFirst":
                    commit("removeFirstLifetimeMacroDescriptionVisibleLastSlides");
                    return;
                default:
                    commit("changeLifetimeMacroDescriptionVisibleLastSlides", lifetimeMacroDescriptionVisibleLastEntity);
                    return;
            }
        },
        updateLifetimeMacroDescriptionSliderLeftButtonStatus({ commit }, lifetimeMacroDescriptionSliderLeftButtonStatus) {
            commit("changeLifetimeMacroDescriptionSliderLeftButtonStatus", lifetimeMacroDescriptionSliderLeftButtonStatus);
        },
        updateLifetimeMacroDescriptionSliderRightButtonStatus({ commit }, lifetimeMacroDescriptionSliderRightButtonStatus) {
            commit("changeLifetimeMacroDescriptionSliderRightButtonStatus", lifetimeMacroDescriptionSliderRightButtonStatus);
        },
        updateLifetimeMacroDescriptionHasPhotosToUploadStatus({ commit }, lifetimeMacroDescriptionHasPhotosToUploadStatus) {
            commit("changeLifetimeMacroDescriptionHasPhotosToUploadStatus", lifetimeMacroDescriptionHasPhotosToUploadStatus);
        },
    },
    mutations: {
        updateLifetimeMacroDescriptionPhotoLoader(state, condition) {
            state.isLifetimeMacroDescriptionLoading = condition;
        },
        changeLifetimeMacroDescriptionPhotosArchive(state, lifetimeMacroDescriptionPhotosArchive) {
            state.lifetimeMacroDescriptionPhotosArchive = lifetimeMacroDescriptionPhotosArchive;
        },
        changeLifetimeMicropreparationPhotosArchive(state, lifetimeMicropreparationPhotosArchive) {
            state.lifetimeMicropreparationPhotosArchive = lifetimeMicropreparationPhotosArchive;
        },
        changeLifetimeMacroDescriptionPhotos(state, lifetimeMacroDescriptionPhotos) {
            state.lifetimeMacroDescriptionPhotos = lifetimeMacroDescriptionPhotos;
        },
        clearLifetimeMacroDescriptionPhotos(state) {
            state.lifetimeMacroDescriptionPhotos = [];
        },
        editLifetimeMacroDescriptionPhoto(state, [lifetimeMacroDescriptionPhotoId, annotations]) {
            const index = state.lifetimeMacroDescriptionPhotos.findIndex(image => image.id === lifetimeMacroDescriptionPhotoId);
            state.lifetimeMacroDescriptionPhotos[index].annotations = annotations;
        },
        deleteLifetimeMacroDescriptionPhoto(state,  lifetimeMacroDescriptionPhotoId) {
            state.lifetimeMacroDescriptionPhotos = state.lifetimeMacroDescriptionPhotos.filter(
                image => image.id !== lifetimeMacroDescriptionPhotoId
            );
        },
        changeLifetimeMacroDescriptionCurrentPhotoIndex(state, lifetimeMacroDescriptionCurrentPhotoIndex) {
            state.lifetimeMacroDescriptionCurrentPhotoIndex = lifetimeMacroDescriptionCurrentPhotoIndex;
        },
        // VisibleSlides
        changeLifetimeMacroDescriptionVisibleSlides(state, lifetimeMacroDescriptionVisibleSlides) {
            state.lifetimeMacroDescriptionVisibleSlides = lifetimeMacroDescriptionVisibleSlides;
        },
        addLastLifetimeMacroDescriptionVisibleSlides(state, lastElementAdded) {
            state.lifetimeMacroDescriptionVisibleSlides = [...state.lifetimeMacroDescriptionVisibleSlides, lastElementAdded];
        },
        addFirstLifetimeMacroDescriptionVisibleSlides(state, firstElementAdded) {
            state.lifetimeMacroDescriptionVisibleSlides = [firstElementAdded, ...state.lifetimeMacroDescriptionVisibleSlides];
        },
        removeLastLifetimeMacroDescriptionVisibleSlides(state) {
            state.lifetimeMacroDescriptionVisibleSlides.pop();
        },
        removeFirstLifetimeMacroDescriptionVisibleSlides(state) {
            state.lifetimeMacroDescriptionVisibleSlides.shift();
        },

        // VisibleLastSlides
        changeLifetimeMacroDescriptionVisibleLastSlides(state, lifetimeMacroDescriptionVisibleLastSlides) {
            state.lifetimeMacroDescriptionVisibleLastSlides = lifetimeMacroDescriptionVisibleLastSlides;
        },
        addFirstLifetimeMacroDescriptionVisibleLastSlides(state, firstElementAdded) {
            state.lifetimeMacroDescriptionVisibleLastSlides = [firstElementAdded, ...state.lifetimeMacroDescriptionVisibleLastSlides];
        },
        removeFirstLifetimeMacroDescriptionVisibleLastSlides(state) {
            state.lifetimeMacroDescriptionVisibleLastSlides.shift();
        },

        // buttons
        changeLifetimeMacroDescriptionSliderLeftButtonStatus(state, lifetimeMacroDescriptionSliderLeftButtonStatus) {
            state.lifetimeMacroDescriptionSliderLeftButtonStatus = lifetimeMacroDescriptionSliderLeftButtonStatus;
        },
        changeLifetimeMacroDescriptionSliderRightButtonStatus(state, lifetimeMacroDescriptionSliderRightButtonStatus) {
            state.lifetimeMacroDescriptionSliderRightButtonStatus = lifetimeMacroDescriptionSliderRightButtonStatus;
        },
        changeLifetimeMacroDescriptionHasPhotosToUploadStatus(state, lifetimeMacroDescriptionHasPhotosToUploadStatus) {
            state.lifetimeMacroDescriptionHasPhotosToUploadStatus = lifetimeMacroDescriptionHasPhotosToUploadStatus;
        },
    },
    getters: {
        getLifetimeMacroDescriptionPhotosArchive(state) {
            return state.lifetimeMacroDescriptionPhotosArchive;
        },
        getLifetimeMicropreparationPhotosArchive(state) {
            return state.lifetimeMicropreparationPhotosArchive;
        },
        getLifetimeMacroDescriptionPhotoLoaderStatus(state) {
            return state.isLifetimeMacroDescriptionLoading;
        },
        getLifetimeMacroDescriptionPhotos(state) {
            return state.lifetimeMacroDescriptionPhotos;
        },
        getLifetimeMacroDescriptionCurrentPhotoIndex(state) {
            return state.lifetimeMacroDescriptionCurrentPhotoIndex;
        },
        getLifetimeMacroDescriptionVisibleSlides(state) {
            return state.lifetimeMacroDescriptionVisibleSlides;
        },
        getLifetimeMacroDescriptionVisibleLastSlides(state) {
            return state.lifetimeMacroDescriptionVisibleLastSlides;
        },
        getLifetimeMacroDescriptionCountOfVisibleSlides(state) {
            return state.lifetimeMacroDescriptionCountOfVisibleSlides;
        },
        getLifetimeMacroDescriptionSliderLeftButtonStatus(state) {
            return state.lifetimeMacroDescriptionSliderLeftButtonStatus;
        },
        getLifetimeMacroDescriptionSliderRightButtonStatus(state) {
            return state.lifetimeMacroDescriptionSliderRightButtonStatus;
        },
        getLifetimeMacroDescriptionHasPhotosToUploadStatus(state) {
            return state.lifetimeMacroDescriptionHasPhotosToUploadStatus;
        },
    }
}