import { mapGetters } from "vuex";

export const permissionMixin = {
    computed: {
        ...mapGetters([
            "getCurrentUserPermissions",
        ]),
    },
    methods: {
        hasPermission(permission) {
            // Проверяем, есть ли у пользователя необходимое разрешение
            return this.getCurrentUserPermissions?.[permission] || false;
        }
    }
};
