import { lifetimeApi } from "@/api";
export default {
    state: {
        charts: [],
        graphs: [],
        metricsLoader: false,
    },

    actions: {
        async fetchMetrics({ dispatch, commit }, queryParams) {
            commit("upadeMetricsLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const metrics = await lifetimeApi.fetchMetrics(queryParams, token);
                    commit("updateCharts", metrics.charts);
                    commit("updateGraphs", metrics.graphs[0]);
                    commit("upadeMetricsLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("upadeMetricsLoader", false);
                    return;
                }
            } catch (error) {
                console.log(error);
                commit("upadeMetricsLoader", false);
            }
        },
    },

    mutations: {
        updateCharts(state, charts) {
            state.charts = charts;
        },
        updateGraphs(state, graphs) {
            state.graphs = graphs;
        },
        upadeMetricsLoader(state, loader) {
            state.metricsLoader = loader;
        },
    },

    getters: {
        getCharts(state) {
            return state.charts;
        },
        getGraphs(state) {
            return state.graphs;
        },
        getMetricsLoader(state) {
            return state.metricsLoader;
        },
    }
}
