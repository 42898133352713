import { lifetimeApi } from "@/api";

export default {
    state: {
        isIntegrationDialogVisible: false,
        integrationIds: null
    },
    actions: {
        updateIntegrationDialogVisibility({ commit }, isVisible) {
            commit("changeIntegrationDialogVisibility", isVisible);
        },
        async createSemdIntegration({ dispatch, commit }, integrationData) {
            try {
                const token = await dispatch('getAccessToken');
                if (token) {
                    const integrationInfo = await lifetimeApi.createSemdIntegration(integrationData, token);
                    if (integrationInfo.semd_ids) {
                        commit("updateIntegrationDataIds", integrationInfo.semd_ids);
                        dispatch("addToastMessage", {code: 200, message: "Документы отправлены в очередь на выгрузку."});
                    }
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return false;
                }
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        async reformatSemdIntegration({ dispatch }, semdId) {
            try {
                const token = await dispatch('getAccessToken');
                if (token) {
                    const integrationInfo = await lifetimeApi.reformatSemdIntegration(semdId, token);
                    console.log(integrationInfo);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return false;
                }
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        async signSemdIntegration({ dispatch }, singData) {
            try {
                const token = await dispatch('getAccessToken');
                if (token) {
                    const signInfo = await lifetimeApi.signSemdIntegration(singData, token);
                    console.log(signInfo);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    return false;
                }
            } catch (error) {
                console.log(error);
            }
        }
    },
    mutations: {
        changeIntegrationDialogVisibility(state, isVisible) {
            state.isIntegrationDialogVisible = isVisible;
        },
        updateIntegrationDataIds(state, ids) {
            state.integrationIds = ids;
        }
    },
    getters: {
        getIntegrationDialogVisibility(state) {
            return state.isIntegrationDialogVisible;
        },
        getIntegrationIds(state) {
            return state.integrationIds;
        }
    },
}