import { checking } from "@/mixins";

export const fullName = {
    mixins: [checking],
    computed: {
        getFullName() {
            return (objectData) => {
                const surname = this.checkingStringData(objectData?.last_name);
                const name = this.checkingStringData(objectData?.first_name);
                const patronymic = this.checkingStringData(objectData?.patronymic);

                return surname + ' ' + name + ' ' + patronymic;
            }
        },
        fullPatientName() {
            return (patientObjectData) => {
                const surname = this.checkingStringData(patientObjectData?.surname);
                const name = this.checkingStringData(patientObjectData?.name);
                const patronymic = this.checkingStringData(patientObjectData?.patronymic);

                return surname + ' ' + name + ' ' + patronymic;
            }
        },
        fullDoctorName() {
            return (doctorObjectData) => {
                const surname = this.checkingStringData(doctorObjectData?.request_organization?.request_organization_surname);
                const name = this.checkingStringData(doctorObjectData?.request_organization?.request_organization_name);
                const patronymic = this.checkingStringData(doctorObjectData?.request_organization?.request_organization_patronymic);

                return surname + ' ' + name + ' ' + patronymic;
            }
        },
        getSurnameInitials() {
            return (nameData) => {
                let surname = "";
                let name = "";
                let patronymic = "";

                if (typeof nameData === 'object') {
                    surname = this.checkingStringData(nameData?.surname);
                    name = this.checkingStringData(nameData?.name);
                    patronymic = this.checkingStringData(nameData?.patronymic);
                }
                if (typeof nameData === 'string') {
                    const fullName = this.checkingStringData(nameData).split(" ");

                    surname = fullName[0];
                    name = fullName[1];
                    patronymic = fullName[2];
                }

                let surnameInitials = [];
                surname ? surnameInitials.push(surname) : null;
                name ? surnameInitials.push(name[0] + ".") : null;
                patronymic ? surnameInitials.push(patronymic[0] + ".") : null;

                return surnameInitials.join(" ");
            }
        }
    }
};