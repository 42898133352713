import { lifetimeApi } from "@/api";
import { lifetimeApiWS } from "@/api";

export default {
    state: {
        semdIntegrationFiltersButtonStatus: false,
        semdIntegrationFilterData: {},
        semdIntegrations: [],
        semdIntegrationById: [],
        selectedSemds: null,
        semdIntegrationTotalPages: null,
        semdIntegrationTotalCount: null,
        isSemdIntegrationLoading: false,
        semdIntegrationJournalWsConnection: null,
    },
    actions: {
        async connectToSemdIntegrationJournalWS({ commit, dispatch, state }, payload) {

            console.warn("Attempting to connect to Integration Table WebSocket...");

            const [getSemdIntegrationAction, queryParams] = payload || [];

            const token = await dispatch("getAccessToken");

            if (!token) {
                console.warn("Token not found, redirecting to auth...");
                dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                return;
            }

            const socket = state.semdIntegrationJournalWsConnection;

            if (socket && (socket.readyState === WebSocket.OPEN)) {
                console.warn("WebSocket is already connected");
                return;
            }

            if (socket && (socket.readyState === WebSocket.CONNECTING)) {
                console.warn("WebSocket is connecting...");
                return;
            }

            // Todo: стоит улучшить
            if (socket && (socket.readyState === WebSocket.CLOSING || socket.readyState === WebSocket.CLOSED)) {
                console.warn("Waiting for WebSocket to close...");
                await new Promise((resolve) => {
                    const handleClose = () => {
                        console.warn('WebSocket closed, attempting to reconnect...');
                        resolve();
                    };
                    socket.addEventListener('close', handleClose);
                    // Принудительно закрываем, если в состоянии CLOSING
                    if (socket.readyState === WebSocket.CLOSING) {
                        socket.close();
                    }
                    // Дополнительная уверенность
                    if (socket.readyState === WebSocket.CLOSED) {
                        resolve();
                    }
                });
            }

            try {
                const newSocket = await lifetimeApiWS.requestSemdIntegrationJournal(token);
                
                commit("changeSemdIntegrationJournalWsConnection", newSocket);

                newSocket.onmessage = (event) => {
                    console.warn("WebSocket message received:", event.data);
                    const data = JSON.parse(event.data);
                    if (data.response_status === 403) {
                        console.warn("Received 403 response, attempting to reconnect...");
                        dispatch("disconnectFromSemdIntegrationJournalWS");
                        dispatch("reconnectSemdIntegrationJournalWS");
                    }
                    if (data?.data?.semd_id) {
                        if (getSemdIntegrationAction) {
                            getSemdIntegrationAction([queryParams, true]); 
                        }
                    }
                };

                newSocket.onclose = (event) => {
                    console.warn("WebSocket connection closed:", event);
                    dispatch("reconnectSemdIntegrationJournalWS");
                };

                newSocket.onerror = (error) => {
                    console.error("WebSocket error:", error);
                    dispatch("reconnectSemdIntegrationJournalWS");
                };

                console.warn("WebSocket connection established");
            } catch (error) {
                console.error("Error connecting to WebSocket:", error);
                dispatch("reconnectSemdIntegrationJournalWS");
            }
        },
        disconnectFromSemdIntegrationJournalWS({ commit, state }) {
            const socket = state.semdIntegrationJournalWsConnection;
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close();
                console.warn("WebSocket connection closed");
            } else {
                console.warn("WebSocket was already closed or not established");
            }
            commit("changeSemdIntegrationJournalWsConnection", null);
        },
        async reconnectSemdIntegrationJournalWS({ dispatch }) {
            console.warn("Reconnecting WebSocket...");
            setTimeout(() => {
                dispatch("connectToSemdIntegrationJournalWS");
            }, 1000);
        },

        async fetchSemdIntegrations({ dispatch, commit }, [queryParams, isFetchByWebSocket = false]) {
            if (!isFetchByWebSocket) {
                commit("updateSemdIntegrationLoader", true);
            }
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const semdIntegrations = await lifetimeApi.getSemdIntegrationData(queryParams, token);
                    commit("updateSemdIntegrations", semdIntegrations);
                    commit("updateSemdIntegrationTotalPages", semdIntegrations.total_pages);
                    commit("updateSemdIntegrationsTotalCount", semdIntegrations.count);
                    commit("updateSemdIntegrationLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSemdIntegrationLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSemdIntegrationLoader", false);
            }

        },
        async fetchMoreSemdIntegrations({ dispatch, commit }, queryParams) {
            commit("updateSemdIntegrationLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const semdIntegrations = await lifetimeApi.getSemdIntegrationData(queryParams, token);
                    commit("updateMoreSemdIntegrations", semdIntegrations.results);
                    commit("updateSemdIntegrationLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSemdIntegrationLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSemdIntegrationLoader", false);
            }

        },
        async fetchSemdIntegrationById({ dispatch, commit }, semdId) {
            commit("updateSemdIntegrationLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const semdIntegrationById = await lifetimeApi.getSemdIntegrationDataById(semdId, token);
                    commit("updateSemdIntegrationById", semdIntegrationById);
                    commit("updateSemdIntegrationLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateSemdIntegrationLoader", false);
                }
            } catch (error) {
                console.log(error);
                commit("updateSemdIntegrationLoader", false);
            }

        },
        updateSemdIntegrationFiltersButtonStatus({ commit }, semdIntegrationFiltersButtonStatus) {
            commit("changeSemdIntegrationFiltersButtonStatus", semdIntegrationFiltersButtonStatus);
        },
        updateSemdIntegrationFilterData({ commit }, semdIntegrationFilterData) {
            commit("changeSemdIntegrationFilterData", semdIntegrationFilterData);
        },
        updateSelectedSemds({ commit }, selectedSemds) {
            commit("changeSelectedSemds", selectedSemds);
        },
    },
    mutations: {
        changeSemdIntegrationJournalWsConnection(state, semdIntegrationJournalWsConnection) {
            console.warn("Updating WebSocket connection state");
            state.semdIntegrationJournalWsConnection = semdIntegrationJournalWsConnection;
        },
        updateSemdIntegrations(state, semdIntegrations) {
            state.semdIntegrations = semdIntegrations;
        },
        updateSemdIntegrationLoader(state, condition) {
            state.isSemdIntegrationLoading = condition;
        },
        updateMoreSemdIntegrations(state, semdIntegrations) {
            state.semdIntegrations = [...state.semdIntegrations, ...semdIntegrations];
        },
        updateSemdIntegrationTotalPages(state, semdIntegrationTotalPages) {
            state.semdIntegrationTotalPages = semdIntegrationTotalPages;
        },
        updateSemdIntegrationsTotalCount(state, semdIntegrationTotalCount) {
            state.semdIntegrationTotalCount = semdIntegrationTotalCount;
        },
        changeSemdIntegrationFiltersButtonStatus(state, semdIntegrationFiltersButtonStatus) {
            state.semdIntegrationFiltersButtonStatus = semdIntegrationFiltersButtonStatus;
        },
        changeSemdIntegrationFilterData(state, semdIntegrationFilterData) {
            state.semdIntegrationFilterData = semdIntegrationFilterData;
        },
        changeSelectedSemds(state, selectedSemds) {
            state.selectedSemds = selectedSemds;
        },
        updateSemdIntegrationById(state, semdIntegrationById) {
            state.semdIntegrationById = semdIntegrationById;
        }
    },
    getters: {
        getSemdIntegrations(state) {
            return state.semdIntegrations;
        },
        getSemdIntegrationLoaderStatus(state) {
            return state.isSemdIntegrationLoading;
        },
        getSemdIntegrationTotalPages(state) {
            return state.semdIntegrationTotalPages;
        },
        getSemdIntegrationTotalCount(state) {
            return state.semdIntegrationTotalCount;
        },
        getSemdIntegrationFiltersButtonStatus(state) {
            return state.semdIntegrationFiltersButtonStatus;
        },
        getSemdIntegrationFilterData(state) {
            return state.semdIntegrationFilterData;
        },
        getSelectedSemds(state) {
            return state.selectedSemds;
        },
        getSemdIntegrationById(state) {
            return state.semdIntegrationById;
        }
    }
}