export const lifetimeInformationTableFields = {
    data() {
        return {
            lifetimeInformationTableDescriptionData: [
                { field: "download", header: "", columnWidth: "40px" },
                { field: "file_name", header: "Название", columnWidth: "650px"},
                { field: "file_size", header: "Размер", columnWidth: "100px" },
                { field: "created_at", header: "Дата добавления", columnWidth: "150px" },
                { field: "request_employee_position", header: "Добавил", columnWidth: "170px" }
            ],
            lifetimeCaseStageInformationTableData: [
                { field: "stage", header: "Этап", columnWidth: "436px" },
                { field: "responsible", header: "Добавил", columnWidth: "436px"},
                { field: "set_timestamp", header: "Начало", columnWidth: "128px" },
                { field: "end_date", header: "Окончание", columnWidth: "128px" },
            ],
            lifetimeCaseObjectInformationTableDescriptionData: [
                { field: "registration_number", header: "Номер объекта", columnWidth: "200px" },
                { field: "object_type", header: "Тип объекта", columnWidth: "150px"},
                { field: "stage", header: "Этап", columnWidth: "150px"},
                { field: "responsible_employee", header: "Отвественный", columnWidth: "280px"},
                { field: "beginning", header: "Дата начала", columnWidth: "160px" },
                { field: "ending", header: "Дата окончания", columnWidth: "160px" },
            ],
        };
    },

}