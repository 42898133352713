import {
    getAuthToken,
    getRefreshAuthToken,
    getParams,
    getLifetimeCasesParams,
    getSamplePreparationParams,
    getLifeCasesPath,
    getLifetimeCaseByIdPath,
    getLifetimeCasesBiopsyMaterialPath,
    putLifetimeCaseRegisterNumberNewPath,
    postLifetimeCaseRegisterNumberExtendPath,
    postLifetimeCaseRegisterNumberInvalidatePath,
    postLifetimeCaseRegisterNumberNewCasePath,
    addLifetimeNewCasePath,
    patchLifetimeCaseByIdPath,
    getReferenceIcdCodePath,
    getReferenceIcdCodeOncologyPath,
    getReferenceLocalizationPath,
    getReferenceBiopsyPositionPath,
    getReferenceBiopsyGenderPath,
    getReferenceBiopsyFormPath,
    getReferenceOrganizationPath,
    getReferenceOrganizationDepartmentPath,
    patchReferenceOrganizationDepartmentPath,
    getReferenceOrganizationDepartmentDoctorPath,
    getReferencePositionPath,
    getSettingsReferenceStaffPath,
    getSettingsReferenceMaterialPath,
    getSettingsReferenceBiopsyTypePath,
    getSettingsReferenceDefectTypeMaterialPath,
    postSettingsReferenceStaffPath,
    patchSettingsReferenceStaffPath,
    getSettingsReferenceOrganizationPath,
    patchSettingsReferenceOrganizationPath,
    getSettingsReferenceDepartmentPath,
    postSettingsReferenceDepartmentPath,
    patchSettingsReferenceDepartmentPath,
    getSettingsReferenceDoctorPath,
    postSettingsReferenceDoctorPath,
    patchSettingsReferenceDoctorPath,
    getSettingsReferenceLocalizationPath,
    getSettingsReferenceEquipmentPath,
    getSettingsReferenceWiringProgramPath,
    getSettingsReferenceDecaltinationAgentPath,
    getSettingsLifetimeGeneralPath,
    patchSettingsLifetimeGeneralPath,
    getSettingsRegistrationNumbersTemplatesPath,
    patchSettingsRegistrationNumbersTemplatesPath,
    getSettingsOrganizationPath,
    getSettingsProductionCalendarPath,
    postSettingsProductionCalendarPath,
    getSettingsDaysOffInLawPath,
    getPatientByIPAPath,
    getMortemCasesPath,
    getMortemCaseByIdPath,
    addMortemNewCasePath,
    patchMortemCaseByIdPath,
    getMortemCaseArchivePath,
    getCaseArchivePath,
    getUserPath,
    postUserSessionPath,
    getUserByIdPath,
    getLifetimeCasesMacroDescriptionPhotosPath,
    getLifetimePhotosArchivePath,
    getMicropreparationPhotosArchivePath,
    addLifetimeCasesMacroDescriptionPhotosPath,
    lifetimeCaseInformationFilesPath,
    deleteLifetimeCasesMacroDescriptionPhotoPath,
    deleteLifetimeCaseInformationFilesPath,
    getLifetimeCasesMacroDescriptionAllFlasksPath,
    addLifetimeCasesMacroDescriptionFlaskPath,
    getLifetimeCasesMacroDescriptionFlaskPath,
    editLifetimeCasesMacroDescriptionFlaskPath,
    deleteLifetimeCasesMacroDescriptionFlaskPath,
    getLifetimeCasesMacroDescriptionAllCassettesPath,
    addLifetimeCasesMacroDescriptionCassettePath,
    getLifetimeCasesMacroDescriptionCassettePath,
    editLifetimeCasesMacroDescriptionCassettePath,
    deleteLifetimeCasesMacroDescriptionCassettePath,
    getLifetimeCasesMacroDescriptionAllMicropreparationsPath,
    addLifetimeCasesMacroDescriptionMicropreparationPath,
    getLifetimeCasesMacroDescriptionMicropreparationPath,
    editLifetimeCasesMacroDescriptionMicropreparationPath,
    deleteLifetimeCasesMacroDescriptionMicropreparationPath,
    patchLifetimeCaseStatusPath,
    patchLifetimeCaseStatusSetWorkInProgressPath,
    patchLifetimeCaseStatusUnsetWorkInProgressPath,
    patchLifetimeCaseStatusUpdateRegisterPath,
    postLifetimeCaseStatusSetReceptionPath,
    patchLifetimeCaseStatusUpdateReceptionPath,
    postLifetimeCaseStatusSetMacroscopyPath,
    patchLifetimeCaseStatusUpdateMacroscopyPath,
    postLifetimeCaseStatusSetSamplePreparationPath,
    patchLifetimeCaseStatusUpdateSamplePreparationPath,
    patchLifetimeCaseStatusUpdateMicroscopyPath,
    postLifetimeCaseStatusSetDonePath,
    getMicropraparationColoringListPath,
    getSettingsReferenceColoringMethodsPath,
    getReferenceMedicalServicesPath,
    // postLifetimeCaseMaterialDefectPath,
    putLifetimeCaseMaterialDefectPath,
    deleteLifetimeCaseMaterialDefectPath,
    // postLifetimeCaseReferralDefectPath,
    putLifetimeCaseReferralDefectPath,
    deleteLifetimeCaseReferralDefectPath,
    getReferenceDefectsPath,
    getReferenceFlasksDefectsPath,
    getReferenceSamplePreparationDefectsPath,
    getReferenceSamplePreparationDefectsTypesPath,
    samplePreparationCassetteDefectPath,
    samplePreparationMicropreparationDefectPath,
    getSamplePreparationCassettePath,
    getLifetimeMicroPreparationsPath,
    getSemdIntegrationDataPath,
    getReformatSemdIntegrationDataPath,
    getSemdIntegrationDataByIdPath,
    postSignSemdIntegrationDataPath,
    getLifetimeCasesMicroscopyMicropreparationsPhotosPath,
    addLifetimeCasesMicroscopyMicropreparationsPhotosPath,
    deleteLifetimeCasesMicroscopyMicropreparationsPhotosPath,
    getLifetimeStagesPath,
    getStagesTaskPath,
    putLifetimeWiringStagePath,
    putLifetimeStartSamplePreparationCitoPath,
    putLifetimeFillingStagePath,
    putLifetimeMicrotomiaStagePath,
    putLifetimeColoringStagePath,
    putLifetimeDecaltinationStagePath,
    putLifetimeDoficsationStagePath,
    patchReturnInitialStagePath,
    patchReturnInitialMicropraparationStagePath,
    getReferenceResponsibleStaffMemberPath,
    requestPdfGenerationPath,
    requestLifeCasesJournalPath,
    requestSemdIntegrationJournalPath,
    requestSamplePreparationJournalPath,
    getDictionariesNsiPath,
    getDictionariesDepartmentsPath,
    getDictionariesPositionsPath,
    getDictionariesOrganizationsPath,
    getSettingsReferencePermissionsPath,
    postSettingsReferencePermissionsGroupPath,
    patchSettingsReferencePermissionsGroupPath,
    deleteSettingsReferencePermissionsGroupPath,
    getSettingsReferenceEmployeesPath,
    patchSettingsReferenceEmployeesPath,
    getSettingsReferenceEmployeesPositionPath,
    patchSettingsReferenceEmployeesPositionPath,
    getSettingsTimezonePath,
    getDictionariesPaymentPath,
    getDictionariesPlacementPath,
    getDictionariesInsurancePath,
    getDictionariesSubjectPath,
    getMetricsPath,
    generateMisIntegrationXlsxFilePath
} from "@/api/endpoints";

import {
    executeRequestToServer,
    executeGetAuth,
    executeGetRefreshToken,
    executePostRequestToServer,
    executePostFormDataRequestToServer,
    executeWSConnectionToServer,
    executePdfRequestToServer
} from "@/api/services/performers";


export const api = {
    getToken: async (authData) => executeGetAuth(getAuthToken(), authData),
    getRefreshToken: async () => executeGetRefreshToken(getRefreshAuthToken()),
    getUser: async (userId, token) => executeRequestToServer(getUserByIdPath(userId), "GET", token),
    getUsers: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getUserPath()), "GET", token
    ),
    postUserSession: async (employeePositionsData, token) => executeRequestToServer((postUserSessionPath()), "POST", token, employeePositionsData),
    postUser: async (userData, token) => executeRequestToServer((getUserPath()), "POST", token, userData),
    patchUser: async (updatedUserData, userId, token) => executeRequestToServer(getUserByIdPath(userId), "PATCH", token, updatedUserData),
    getPatientByIPA: async (page, page_size, token, search) => executeRequestToServer(
        getParams(page, page_size, search)(getPatientByIPAPath(search)), "GET", token
    ),
    getReferenceIcdCode: async (page, page_size, token, search) => executeRequestToServer(
        getParams(page, page_size, search)(getReferenceIcdCodePath()), "GET", token
    ),
    getReferenceIcdCodeOncology: async (page, page_size, token, search) => executeRequestToServer(
        getParams(page, page_size, search)(getReferenceIcdCodeOncologyPath()), "GET", token
    ),
    getReferenceLocalization: async (page, page_size, token, search) => executeRequestToServer(
        getParams(page, page_size, search)(getReferenceLocalizationPath()), "GET", token
    ),
    //STAFF
    getReferenceResponsibleStaffMember: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getReferenceResponsibleStaffMemberPath()), "GET", token
    ),
    postReferenceResponsibleStaffMember: async (staffData, token) => executeRequestToServer(
        getSettingsReferenceStaffPath(), "POST", token, staffData
    ),
    patchReferenceResponsibleStaffMember: async (staffData, token) => executeRequestToServer(
        getSettingsReferenceStaffPath(), "PATCH", token, staffData
    ),
    //POSITION
    getReferenceBiopsyPosition: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getReferenceBiopsyPositionPath()), "GET", token
    ),
    postReferenceBiopsyPosition: async (positionData, token) => executeRequestToServer((getReferenceBiopsyPositionPath()), "POST", token, positionData),
    patchReferenceBiopsyPosition: async (positionData, token) => executeRequestToServer((getReferenceBiopsyPositionPath()), "PATCH", token, positionData),
    //GENDER
    getReferenceBiopsyGender: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getReferenceBiopsyGenderPath()), "GET", token
    ),
    postReferenceBiopsyGender: async (genderData, token) => executeRequestToServer((getReferenceBiopsyGenderPath()), "POST", token, genderData),
    patchReferenceBiopsyGender: async (genderData, token) => executeRequestToServer((getReferenceBiopsyGenderPath()), "PATCH", token, genderData),
    //FORM
    getReferenceBiopsyForm: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getReferenceBiopsyFormPath()), "GET", token
    ),
    postReferenceBiopsyForm: async (formData, token ) => executeRequestToServer((getReferenceBiopsyFormPath()), "POST", token, formData),
    patchReferenceBiopsyForm: async (formData, token) => executeRequestToServer((getReferenceBiopsyFormPath()), "PATCH", token, formData),
    //ORGANIZATION
    getReferenceOrganization: async (page, page_size, token, search) => executeRequestToServer(
        getParams(page, page_size, search)(getReferenceOrganizationPath()), "GET", token
    ),
    getReferenceOrganizationDepartment: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getReferenceOrganizationDepartmentPath()), "GET", token
    ),
    postReferenceOrganizationDepartment: async (organizationData, token ) => executeRequestToServer(
        getReferenceOrganizationDepartmentPath(), "POST", token, organizationData
    ),
    patchReferenceOrganizationDepartment: async (organizationData, departmentId, token ) => executeRequestToServer(
        patchReferenceOrganizationDepartmentPath(departmentId), "PATCH", token, organizationData
    ),
    getReferenceOrganizationDepartmentDoctor: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getReferenceOrganizationDepartmentDoctorPath()), "GET", token
    ),
    postReferenceOrganizationDepartmentDoctor: async (doctorData, token) => executeRequestToServer(
        getReferenceOrganizationDepartmentDoctorPath(), "POST", token, doctorData
    ),
    patchReferenceOrganizationDepartmentDoctor: async (doctorData, token) => executeRequestToServer(
        getReferenceOrganizationDepartmentDoctorPath(), "PATCH", token, doctorData
    ),
    getReferencePosition: async (token) => executeRequestToServer((getReferencePositionPath()), "GET", token),
    getSettingsReferenceStaff: async (page, page_size, token) => executeRequestToServer(
        getParams(page, page_size)(getSettingsReferenceStaffPath()), "GET", token
    ),
    // БРАК СЛУЧАЯ
    getReferenceDefects: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getReferenceDefectsPath()), "GET", token
    ),
    postReferenceDefects: async (defect, token) => executeRequestToServer((getReferenceDefectsPath()), "POST", token, defect),
    patchReferenceDefects: async (defect, token) => executeRequestToServer((getReferenceDefectsPath()), "PATCH", token, defect),
    // БРАК МАТЕРИАЛА
    getReferenceFlasksDefects: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getReferenceFlasksDefectsPath()), "GET", token
    ),
    postReferenceFlasksDefects: async (data, token) => executeRequestToServer((getReferenceFlasksDefectsPath()), "POST", token, data),
    patchReferenceFlasksDefects: async (data, token) => executeRequestToServer((getReferenceFlasksDefectsPath()), "PATCH", token, data),
    // БРАК ПРОБОПОДГОТОВКИ
    getReferenceSamplePreparationDefects: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getReferenceSamplePreparationDefectsPath()), "GET", token
    ),
    postReferenceSamplePreparationDefects: async (token, data) => executeRequestToServer((getReferenceSamplePreparationDefectsPath()), "POST", token, data),
    patchReferenceSamplePreparationDefects: async (token, data) => executeRequestToServer((getReferenceSamplePreparationDefectsPath()), "PATCH", token, data),

    //ТИПЫ БРАКОВ ПРОБОПОДГОТОВКИ
    getReferenceSamplePreparationDefectsTypes: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getReferenceSamplePreparationDefectsTypesPath()), "GET", token
    ),
    postReferenceSamplePreparationDefectsTypes: async (token, data) => executeRequestToServer((getReferenceSamplePreparationDefectsTypesPath()), "POST", token, data),
    patchReferenceSamplePreparationDefectsTypes: async (token, data) => executeRequestToServer((getReferenceSamplePreparationDefectsTypesPath()), "PATCH", token, data),
    //ВЫСТАВИТЬ/УДАЛИТЬ БРАК ПРОБОПОДГОТОВКИ ДЛЯ КАССЕТ
    postAddSamplePreparationCassetteDefect: async (defectData, token) => executeRequestToServer((samplePreparationCassetteDefectPath()), "POST", token, defectData),
    deleteSamplePreparationCassetteDefect: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(samplePreparationCassetteDefectPath()), "DELETE", token
    ),
    //ВЫСТАВИТЬ/УДАЛИТЬ БРАК ПРОБОПОДГОТОВКИ ДЛЯ МИКРОПЕРПАРАТОВ
    postAddSamplePreparationMicropreparationDefect: async (defectData, token) => executeRequestToServer((samplePreparationMicropreparationDefectPath()), "POST", token, defectData),
    deleteSamplePreparationMicropreparationDefect: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(samplePreparationMicropreparationDefectPath()), "DELETE", token
    ),
    //Медицинские услуги
    getReferenceMedicalServices: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getReferenceMedicalServicesPath()), "GET", token
    ),
    postReferenceMedicalServices: async (medicalServiceData, token) => executeRequestToServer((getReferenceMedicalServicesPath()), "POST", token, medicalServiceData),
    patchReferenceMedicalServices: async (medicalServiceData, token) => executeRequestToServer((getReferenceMedicalServicesPath()), "PATCH", token, medicalServiceData),

    postSettingsReferenceStaff: async (token, staffMemberData) => executeRequestToServer(
        postSettingsReferenceStaffPath(), "POST", token, staffMemberData
    ),
    patchSettingsReferenceStaff: async (staffMemberId, token, staffMemberData) => executeRequestToServer(
        patchSettingsReferenceStaffPath(staffMemberId), "PATCH", token, staffMemberData
    ),
    getSettingsReferenceOrganization: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getSettingsReferenceOrganizationPath()), "GET", token
    ),
    postSettingsReferenceOrganization: async (organizationData, token ) => executeRequestToServer(
        getSettingsReferenceOrganizationPath(), "POST", token, organizationData
    ),
    patchSettingsReferenceOrganization: async (organizationData, organizationId, token ) => executeRequestToServer(
        patchSettingsReferenceOrganizationPath(organizationId), "PATCH", token, organizationData
    ),
    getSettingsReferenceDepartment: async (page, page_size, token) => executeRequestToServer(
        getParams(page, page_size)(getSettingsReferenceDepartmentPath()), "GET", token
    ),
    postSettingsReferenceDepartment: async (organizationId, token, departmentData) => executeRequestToServer(
        postSettingsReferenceDepartmentPath(organizationId), "POST", token, departmentData
    ),
    patchSettingsReferenceDepartment: async (organizationId, departmentId, token, departmentData) => executeRequestToServer(
        patchSettingsReferenceDepartmentPath(organizationId, departmentId), "PATCH", token, departmentData
    ),
    getSettingsReferenceDoctor: async (page, page_size, token) => executeRequestToServer(
        getParams(page, page_size)(getSettingsReferenceDoctorPath()), "GET", token
    ),
    postSettingsReferenceDoctor: async (organizationId, departmentId, token, doctorData) => executeRequestToServer(
        postSettingsReferenceDoctorPath(organizationId, departmentId), "POST", token, doctorData
    ),
    patchSettingsReferenceDoctor: async (organizationId, departmentId, doctorId, token, doctorData) => executeRequestToServer(
        patchSettingsReferenceDoctorPath(organizationId, departmentId, doctorId), "PATCH", token, doctorData
    ),
    //LOCALIZATION
    getSettingsReferenceLocalization: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getSettingsReferenceLocalizationPath()), "GET", token
    ),
    postSettingsReferenceLocalization: async (localizationData, token) => executeRequestToServer(
        getSettingsReferenceLocalizationPath(), "POST", token, localizationData
    ),
    patchSettingsReferenceLocalization: async (localizationData, token ) => executeRequestToServer(
        getSettingsReferenceLocalizationPath(), "PATCH", token, localizationData
    ),
    //EQUIPMENT
    getSettingsReferenceEquipment: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getSettingsReferenceEquipmentPath()), "GET", token
    ),
    postSettingsReferenceEquipment: async (equipmentData, token) => executeRequestToServer(
        getSettingsReferenceEquipmentPath(), "POST", token, equipmentData
    ),
    patchSettingsReferenceEquipment: async (equipmentData, token ) => executeRequestToServer(
        getSettingsReferenceEquipmentPath(), "PATCH", token, equipmentData
    ),
    //WIRING PROGRAM
    getSettingsReferenceWiringProgram: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getSettingsReferenceWiringProgramPath()), "GET", token
    ),
    //DECALTINATION AGENT
    getSettingsReferenceDecaltinationAgent: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getSettingsReferenceDecaltinationAgentPath()), "GET", token
    ),
    getSettingsLifetimeGeneral: async (token) => executeRequestToServer(getSettingsLifetimeGeneralPath(), "GET", token),
    patchSettingsLifetimeGeneral: async (token, settingsLifetimeGeneralData) => executeRequestToServer(
        patchSettingsLifetimeGeneralPath(), "PATCH", token, settingsLifetimeGeneralData
    ),
    getSettingsReferenceMaterial: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getSettingsReferenceMaterialPath()), "GET", token
    ),
    postSettingsReferenceMaterial: async (materialData, token ) => executeRequestToServer(
        getSettingsReferenceMaterialPath(), "POST", token, materialData
    ),
    patchSettingsReferenceMaterial: async (materialData, token ) => executeRequestToServer(
        getSettingsReferenceMaterialPath(), "PATCH", token, materialData
    ),
    getSettingsReferenceBiopsyType: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getSettingsReferenceBiopsyTypePath()), "GET", token
    ),
    postSettingsReferenceBiopsyType: async (biopsyData, token ) => executeRequestToServer(
        getSettingsReferenceBiopsyTypePath(), "POST", token, biopsyData
    ),
    patchSettingsReferenceBiopsyType: async (biopsyData, token ) => executeRequestToServer(
        getSettingsReferenceBiopsyTypePath(), "PATCH", token, biopsyData
    ),
    getSettingsReferenceDefectTypeMaterial: async (page, page_size, token) => executeRequestToServer(
        getParams(page, page_size)(getSettingsReferenceDefectTypeMaterialPath()), "GET", token
    ),

    //SETTINGS REGISTRATION NUMBERS TEMPLATES
    getSettingsRegistrationNumbersTemplates: async (token) => executeRequestToServer(getSettingsRegistrationNumbersTemplatesPath(), "GET", token),
    patchSettingsRegistrationNumbersTemplates: async (numberId, regNumberTemplateData, token) => executeRequestToServer(patchSettingsRegistrationNumbersTemplatesPath(numberId), "PATCH", token, regNumberTemplateData),

    //SETTINGS ORGANIZATION
    getSettingsOrganization: async (token) => executeRequestToServer(getSettingsOrganizationPath(), "GET", token),
    patchSettingsOrganization: async (organizationData, token) => executeRequestToServer(getSettingsOrganizationPath(), "PATCH", token, organizationData),

    //SETTINGS PRODUCTION CALENDAR
    getSettingsProductionCalendar: async (token, queryParams) => executeRequestToServer(getLifetimeCasesParams(queryParams)(getSettingsProductionCalendarPath()), "GET", token),
    postSettingsProductionCalendar: async (token, productionCalendarData) => executeRequestToServer((postSettingsProductionCalendarPath()), "POST", token, productionCalendarData),
    getSettingsDaysOffInLaw: async (token, year) => executeRequestToServer(getSettingsDaysOffInLawPath(year), "GET", token),

    //COLORING LIST
    getSettingsReferenceColoring: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getMicropraparationColoringListPath()), "GET", token
    ),
    postSettingsReferenceColoring: async (coloringData, token ) => executeRequestToServer(
        getMicropraparationColoringListPath(), "POST", token, coloringData
    ),
    patchSettingsReferenceColoring: async (coloringData,  token ) => executeRequestToServer(
        getMicropraparationColoringListPath(), "PATCH", token, coloringData
    ),

    //COLORING METHODS
    getSettingsReferenceColoringMethod: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getSettingsReferenceColoringMethodsPath()), "GET", token
    ),
    postSettingsReferenceColoringMethod: async (coloringData, token ) => executeRequestToServer(
        getSettingsReferenceColoringMethodsPath(), "POST", token, coloringData
    ),
    patchSettingsReferenceColoringMethod: async (coloringData,  token ) => executeRequestToServer(
        getSettingsReferenceColoringMethodsPath(), "PATCH", token, coloringData
    ),

    //NSI
    getDictionariesNsi: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getDictionariesNsiPath()), "GET", token
    ),
    getDictionariesDepartments: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getDictionariesDepartmentsPath()), "GET", token
    ),
    getDictionariesOrganizations: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getDictionariesOrganizationsPath()), "GET", token
    ),
    getDictionariesPositions: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getDictionariesPositionsPath()), "GET", token
    ),
    getDictionariesPayment: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getDictionariesPaymentPath()), "GET", token
    ),
    getDictionariesPlacement: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getDictionariesPlacementPath()), "GET", token
    ),
    getDictionariesInsurance: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getDictionariesInsurancePath()), "GET", token
    ),
    getDictionariesSubject: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getDictionariesSubjectPath()), "GET", token
    ),

    //PERMISSIONS
    getSettingsReferencePermissions: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getSettingsReferencePermissionsPath()), "GET", token
    ),
    postSettingsReferencePermissionsGroup: async (groupData, token) => executeRequestToServer((postSettingsReferencePermissionsGroupPath()), "POST", token, groupData),
    patchSettingsReferencePermissionsGroup: async (groupId, groupData, token) => executeRequestToServer((patchSettingsReferencePermissionsGroupPath(groupId)), "PATCH", token, groupData),
    deleteSettingsReferencePermissionsGroup: async (groupId, token) => executeRequestToServer((patchSettingsReferencePermissionsGroupPath(groupId)), "DELETE", token),
    deleteSettingsReferencePermissionsGroupUncheck: async (groupId, permissionId, token) => executeRequestToServer((deleteSettingsReferencePermissionsGroupPath(groupId, permissionId)), "DELETE", token),

    //EMPLOYEES
    getSettingsReferenceEmployees: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getSettingsReferenceEmployeesPath()), "GET", token
    ),
    postSettingsReferenceEmployees: async (employeeData, token ) => executeRequestToServer(
        getSettingsReferenceEmployeesPath(), "POST", token, employeeData
    ),
    patchSettingsReferenceEmployees: async (employeeData, employeeId,  token ) => executeRequestToServer(
        patchSettingsReferenceEmployeesPath(employeeId), "PATCH", token, employeeData
    ),

    //EMPLOYEES POSITION
    getSettingsReferenceEmployeesPostion: async (queryParams, token) => executeRequestToServer(
        getSamplePreparationParams(queryParams)(getSettingsReferenceEmployeesPositionPath()), "GET", token
    ),
    postSettingsReferenceEmployeesPostion: async (positionData, token ) => executeRequestToServer(
        getSettingsReferenceEmployeesPositionPath(), "POST", token, positionData
    ),
    patchSettingsReferenceEmployeesPostion: async (positionData, positionId, token ) => executeRequestToServer(
        patchSettingsReferenceEmployeesPositionPath(positionId), "PATCH", token, positionData
    ),

    //TIMEZONE
    getTimezoneList: async (token) => executeRequestToServer(getSettingsTimezonePath(), "GET", token),
}

export const lifetimeApi = {
    getLifetimeCases: async (page, page_size, token) => executeRequestToServer(
        getParams(page, page_size)(getLifeCasesPath()), "GET", token //getLifetimeCasesPath()
    ),
    getLifetimeCasesWithSort: async (token, params) => executeRequestToServer(
        getLifetimeCasesParams(params)(getLifeCasesPath()), "GET", token //getLifetimeCasesPath()
    ),
    getLifetimeCaseById: async (caseId, token) => executeRequestToServer(getLifetimeCaseByIdPath(caseId), "GET", token),
    patchLifetimeCaseById: async (caseId, updatedCaseData, token) => executeRequestToServer(patchLifetimeCaseByIdPath(caseId), "PATCH", token, updatedCaseData),
    putLifetimeCaseRegisterNumberNew: async (token) => executeRequestToServer(putLifetimeCaseRegisterNumberNewPath(), "PUT", token),
    postLifetimeCaseRegisterNumberExtend: async (registerNumberId, token) => executePostRequestToServer(postLifetimeCaseRegisterNumberExtendPath(registerNumberId), token),
    postLifetimeCaseRegisterNumberInvalidate: async (registerNumberId, token) => executeRequestToServer(postLifetimeCaseRegisterNumberInvalidatePath(registerNumberId), "DELETE", token),
    postLifetimeCaseRegisterNumberNewCase: async (registerNumberId, newCaseData, token) => executeRequestToServer(postLifetimeCaseRegisterNumberNewCasePath(registerNumberId), "POST", token, newCaseData),
    addLifetimeCase: async (newCaseData, token) => executeRequestToServer(addLifetimeNewCasePath(), "POST", token, newCaseData),
    getLifetimeCasesBiopsyMaterial: async (token) => executeRequestToServer(getLifetimeCasesBiopsyMaterialPath(), "GET", token),
    getLifetimeArchiveCases: async (page, page_size, token, searchParams) => executeRequestToServer(getParams(page, page_size, searchParams)(getCaseArchivePath()), "GET", token),

    // PHOTO
    getLifetimeCasesMacroDescriptionPhotos: async (caseId, token) => executeRequestToServer(getLifetimeCasesMacroDescriptionPhotosPath(caseId), "GET", token),
    getLifetimePhotosArchive: async (caseId, token) => executeRequestToServer(getLifetimePhotosArchivePath(caseId), "GET", token),
    getMicropreparationPhotosArchive: async (micropreparationId, token) => executeRequestToServer(getMicropreparationPhotosArchivePath(micropreparationId), "GET", token),
    addLifetimeCasesMacroDescriptionPhotos: async (caseId, token, newPhotoData) => executePostFormDataRequestToServer(addLifetimeCasesMacroDescriptionPhotosPath(caseId), token, newPhotoData),
    deleteLifetimeCasesMacroDescriptionPhoto: async (caseId, imageId, token) => executeRequestToServer(deleteLifetimeCasesMacroDescriptionPhotoPath(caseId, imageId), "DELETE", token),
    editLifetimeCasesMacroDescriptionPhoto: async (caseId, imageId, token, newPhotoData) => executeRequestToServer(deleteLifetimeCasesMacroDescriptionPhotoPath(caseId, imageId), "PATCH", token, newPhotoData),
    //INFORMATION
    getLifetimeCaseInformationFiles: async (caseId, token) => executeRequestToServer(lifetimeCaseInformationFilesPath(caseId), "GET", token),
    postLifetimeCaseInformationFiles: async (caseId, token, files) => executePostFormDataRequestToServer(lifetimeCaseInformationFilesPath(caseId), token, files),
    deleteLifetimeCaseInformationFiles: async (caseId, fileId, token) => executeRequestToServer(deleteLifetimeCaseInformationFilesPath(caseId, fileId), "DELETE", token),

    // CUT_OBJECT_FLASK
    getLifetimeCasesMacroDescriptionAllFlasks: async (caseId, token) => executeRequestToServer(getLifetimeCasesMacroDescriptionAllFlasksPath(caseId), "GET", token),
    addLifetimeCasesMacroDescriptionFlask: async (caseId, token) => executeRequestToServer(addLifetimeCasesMacroDescriptionFlaskPath(caseId), "POST", token),
    getLifetimeCasesMacroDescriptionFlask: async (caseId, flaskId, token) => executeRequestToServer(getLifetimeCasesMacroDescriptionFlaskPath(caseId, flaskId), "GET", token),
    editLifetimeCasesMacroDescriptionFlask: async (caseId, flaskId, token) => executeRequestToServer(editLifetimeCasesMacroDescriptionFlaskPath(caseId, flaskId), "PUT", token),
    // editLifetimeCasesMacroDescriptionFlask: async (caseId, flaskId, token) => executeRequestToServer(editLifetimeCasesMacroDescriptionFlaskPath(caseId, flaskId), "PATCH", token),
    deleteLifetimeCasesMacroDescriptionFlask: async (caseId, flaskId, token) => executeRequestToServer(deleteLifetimeCasesMacroDescriptionFlaskPath(caseId, flaskId), "DELETE", token),
    // CUT_OBJECT_CASSETTE
    getLifetimeCasesMacroDescriptionAllCassettes: async (caseId, flaskId, token) => executeRequestToServer(getLifetimeCasesMacroDescriptionAllCassettesPath(caseId, flaskId), "GET", token),
    addLifetimeCasesMacroDescriptionCassette: async (flaskId, token) => executeRequestToServer(addLifetimeCasesMacroDescriptionCassettePath(), "POST", token, flaskId),
    getLifetimeCasesMacroDescriptionCassette: async (caseId, flaskId, cassetteId, token) => executeRequestToServer(getLifetimeCasesMacroDescriptionCassettePath(caseId, flaskId, cassetteId), "GET", token),
    editLifetimeCasesMacroDescriptionCassette: async (caseId, flaskId, cassetteId, token) => executeRequestToServer(editLifetimeCasesMacroDescriptionCassettePath(caseId, flaskId, cassetteId), "PUT", token),
    // editLifetimeCasesMacroDescriptionCassette: async (caseId, flaskId, cassetteId, token) => executeRequestToServer(editLifetimeCasesMacroDescriptionCassettePath(caseId, flaskId, cassetteId), "PATCH", token),
    deleteLifetimeCasesMacroDescriptionCassette: async (cassetteId, token) => executeRequestToServer(deleteLifetimeCasesMacroDescriptionCassettePath(cassetteId), "DELETE", token),
    // CUT_OBJECT_MICROPREPARATION
    getLifetimeCasesMacroDescriptionAllMicropreparations: async (caseId, flaskId, cassetteId, token) => executeRequestToServer(getLifetimeCasesMacroDescriptionAllMicropreparationsPath(caseId, flaskId, cassetteId), "GET", token),
    addLifetimeCasesMacroDescriptionMicropreparation: async (cassetteId, token) => executeRequestToServer(addLifetimeCasesMacroDescriptionMicropreparationPath(), "POST", token, cassetteId),
    getLifetimeCasesMacroDescriptionMicropreparation: async (caseId, flaskId, cassetteId, micropreparationId, token) => executeRequestToServer(getLifetimeCasesMacroDescriptionMicropreparationPath(caseId, flaskId, cassetteId, micropreparationId), "GET", token),
    editLifetimeCasesMacroDescriptionMicropreparation: async (newFlasks, token) => executeRequestToServer(editLifetimeCasesMacroDescriptionMicropreparationPath(), "PUT", token, newFlasks),
    deleteLifetimeCasesMacroDescriptionMicropreparation: async (micropreparationId, token) => executeRequestToServer(deleteLifetimeCasesMacroDescriptionMicropreparationPath(micropreparationId), "DELETE", token),
    // STATUSES (statuses, comments, responsible staff, is work in progress, etc)
    patchLifetimeCaseStatus: async (caseId, statusData, token) => executeRequestToServer(patchLifetimeCaseStatusPath(caseId), "PATCH", token, statusData),
    patchLifetimeCaseStatusSetWorkInProgress: async (caseId, statusData, token) => executeRequestToServer(patchLifetimeCaseStatusSetWorkInProgressPath(caseId), "PATCH", token, statusData),
    patchLifetimeCaseStatusUnsetWorkInProgress: async (caseId, token) => executeRequestToServer(patchLifetimeCaseStatusUnsetWorkInProgressPath(caseId), "PATCH", token),
    patchLifetimeCaseStatusUpdateRegister: async (caseId, data, token) => executeRequestToServer(patchLifetimeCaseStatusUpdateRegisterPath(caseId), "PATCH", token, data),
    postLifetimeCaseStatusSetReception: async (caseId, statusData, token) => executeRequestToServer(postLifetimeCaseStatusSetReceptionPath(caseId), "POST", token, statusData),
    patchLifetimeCaseStatusUpdateReception: async (caseId, statusData, token) => executeRequestToServer(patchLifetimeCaseStatusUpdateReceptionPath(caseId), "PATCH", token, statusData),
    postLifetimeCaseStatusSetMacroscopy: async (caseId, statusData, token) => executeRequestToServer(postLifetimeCaseStatusSetMacroscopyPath(caseId), "POST", token, statusData),
    patchLifetimeCaseStatusUpdateMacroscopy: async (caseId, statusData, token) => executeRequestToServer(patchLifetimeCaseStatusUpdateMacroscopyPath(caseId), "PATCH", token, statusData),
    postLifetimeCaseStatusSetSamplePreparation: async (caseId, statusData, token) => executeRequestToServer(postLifetimeCaseStatusSetSamplePreparationPath(caseId), "POST", token, statusData),
    patchLifetimeCaseStatusUpdateSamplePreparation: async (caseId, statusData, token) => executeRequestToServer(patchLifetimeCaseStatusUpdateSamplePreparationPath(caseId), "PATCH", token, statusData),
    patchLifetimeCaseStatusUpdateMicroscopy: async (caseId, statusData, token) => executeRequestToServer(patchLifetimeCaseStatusUpdateMicroscopyPath(caseId), "PATCH", token, statusData),
    postLifetimeCaseStatusSetDone: async (caseId, statusData, token) => executeRequestToServer(postLifetimeCaseStatusSetDonePath(caseId), "POST", token, statusData),

    // postLifetimeCaseMaterialDefect: async (caseId, defectData, token) => executeRequestToServer(postLifetimeCaseMaterialDefectPath(caseId), "POST", token, defectData),
    putLifetimeCaseMaterialDefect: async (caseId, defectData, token) => executeRequestToServer(putLifetimeCaseMaterialDefectPath(caseId), "PUT", token, defectData),
    deleteLifetimeCaseMaterialDefect: async (caseId, token) => executeRequestToServer(deleteLifetimeCaseMaterialDefectPath(caseId), "DELETE", token),

    // postLifetimeCaseReferralDefect: async (caseId, defectData, token) => executeRequestToServer(postLifetimeCaseReferralDefectPath(caseId), "POST", token, defectData),
    putLifetimeCaseReferralDefect: async (caseId, defectData, token) => executeRequestToServer(putLifetimeCaseReferralDefectPath(caseId), "PUT", token, defectData),
    deleteLifetimeCaseReferralDefect: async (caseId, token) => executeRequestToServer(deleteLifetimeCaseReferralDefectPath(caseId), "DELETE", token),
    //SAMPLE PREPARASIONS
    getSamplePreparationCassette: async (queryParams, token) => executeRequestToServer(getSamplePreparationParams(queryParams)(getSamplePreparationCassettePath()), "GET", token),
    getLifetimeStages: async (token) => executeRequestToServer((getLifetimeStagesPath()), "GET", token),
    getStagesTask: async (queryParams,token) => executeRequestToServer(getSamplePreparationParams(queryParams)(getStagesTaskPath()), "GET", token),
    startWiringStage: async (wiringData, token) => executeRequestToServer(putLifetimeWiringStagePath(), "PUT", token, wiringData),
    startSamplePreparationCito: async (samplePreparationData, token) => executeRequestToServer(putLifetimeStartSamplePreparationCitoPath(), "PUT", token, samplePreparationData),
    startFillingStage: async (fillingData, token) => executeRequestToServer(putLifetimeFillingStagePath(), "PUT", token, fillingData),
    startMicrotomiaStage: async (microtomiaData, token) => executeRequestToServer(putLifetimeMicrotomiaStagePath(), "PUT", token, microtomiaData),
    startColoringStage: async (coloringData, token) => executeRequestToServer(putLifetimeColoringStagePath(), "PUT", token, coloringData),
    putEndDecaltinationStage: async (decaltinationData, token) => executeRequestToServer(putLifetimeDecaltinationStagePath(), "PUT", token, decaltinationData),
    putEndDoficsationStage: async (doficsationData, token) => executeRequestToServer(putLifetimeDoficsationStagePath(), "PUT", token, doficsationData),
    patchReturnInitialStage: async (data, token) => executeRequestToServer(patchReturnInitialStagePath(), "PATCH", token, data),
    patchReturnInitialMicropraparationStage: async (data, token) => executeRequestToServer(patchReturnInitialMicropraparationStagePath(), "PATCH", token, data),

    getLifetimeMicroPreparations: async (queryParams, token) => executeRequestToServer(getSamplePreparationParams(queryParams)(getLifetimeMicroPreparationsPath()), "GET", token),
    getLifetimeCasesMicroscopyMicropreparationsPhotos: async (caseId, token) => executeRequestToServer(getLifetimeCasesMicroscopyMicropreparationsPhotosPath(caseId), "GET", token),
    addLifetimeCasesMicroscopyMicropreparationsPhotos: async (caseId, token, newPhotoData) => executePostFormDataRequestToServer(addLifetimeCasesMicroscopyMicropreparationsPhotosPath(caseId), token, newPhotoData),
    deleteLifetimeCasesMicroscopyMicropreparationsPhotos: async (micropreparationId, token, imageId) => executeRequestToServer(deleteLifetimeCasesMicroscopyMicropreparationsPhotosPath(micropreparationId, imageId), "DELETE", token),
    editLifetimeCasesMicroscopyMicropreparationsPhotos: async (micropreparationId, imageId, token, annotations) => executeRequestToServer(deleteLifetimeCasesMicroscopyMicropreparationsPhotosPath(micropreparationId, imageId), "PATCH", token, annotations),
    
    //INTEGRATIONS
    getSemdIntegrationData: async (queryParams, token) => executeRequestToServer(getSamplePreparationParams(queryParams)(getSemdIntegrationDataPath()), "GET", token),
    getSemdIntegrationDataById: async (id, token) => executeRequestToServer(getSemdIntegrationDataByIdPath(id), "GET", token),
    createSemdIntegration: async (integrationData, token) => executeRequestToServer(getSemdIntegrationDataPath(), "POST", token, integrationData),
    reformatSemdIntegration: async (semdId, token) => executeRequestToServer(getReformatSemdIntegrationDataPath(semdId), "POST", token),
    signSemdIntegration: async (signData, token) => executeRequestToServer(postSignSemdIntegrationDataPath(), "POST", token, signData),
    //MIS INTEGRATION
    generateMisIntegrationXlsxFile: async (lifetimecaseIds, token) => executePdfRequestToServer(generateMisIntegrationXlsxFilePath(), "POST", token, lifetimecaseIds),

    //METRICS
    fetchMetrics: async (queryParams, token) => executeRequestToServer(getSamplePreparationParams(queryParams)(getMetricsPath()), "GET", token),
}

export const lifetimeApiWS = {
    //PRINT
    requestPdfGeneration: async (token) => executeWSConnectionToServer(getLifetimeCasesParams({ token })(requestPdfGenerationPath())),
    requestLifeCasesJournal: async (token) => executeWSConnectionToServer(getLifetimeCasesParams({ token })(requestLifeCasesJournalPath())),
    requestSamplePreparationJournal: async (token) => executeWSConnectionToServer(getLifetimeCasesParams({ token })(requestSamplePreparationJournalPath())),
    requestSemdIntegrationJournal: async (token) => executeWSConnectionToServer(getLifetimeCasesParams({ token })(requestSemdIntegrationJournalPath())),
}

export const mortemApi = {
    getMortemCases: async (page, page_size, token, searchParams) => executeRequestToServer(getParams(page, page_size, searchParams)(getMortemCasesPath()), "GET", token),
    getMortemCaseById: async (mortemCaseId, token) => executeRequestToServer(getMortemCaseByIdPath(mortemCaseId), "GET", token),
    patchMortemCaseById: async (mortemCaseId, updatedMortemCaseData, token) => executeRequestToServer(patchMortemCaseByIdPath(mortemCaseId), "PATCH", token, updatedMortemCaseData),
    addMortemCase: async (newMortemCaseData, token) => executeRequestToServer(addMortemNewCasePath(), "POST", token, newMortemCaseData),
    getMortemArchiveCases: async (page, page_size, token, searchParams) => executeRequestToServer(getParams(page, page_size, searchParams)(getMortemCaseArchivePath()), "GET", token),
}