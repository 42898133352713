import { createRouter, createWebHistory } from "vue-router";
import Authorization from "@/views/Authorization.vue";
import store from "@/store";
import {
    getAuthorizationLink,
    getAboutLink,
    getSupportLink,
    getLifetimeCasesLink,
    getLifetimeCaseLink,
    getLifetimeCaseMacroDescriptionPhoto,
    getLifetimeCaseMicroscopyMicropreparationPhoto,
    getLifetimeCasesSamplePreparationLink,
    getSemdIntegrationLink,
    getMetricsLink,
    getReferenceLink,
    getReferenceStaffLink,
    getReferenceEmployeePositionLink,
    getReferenceOrganizationLink,
    getReferenceMaterialLink,
    getReferenceBiopsyTypeLink,
    getReferenceColoringMethodsLink,
    getReferenceColoringsLink,
    getReferenceDepartmentLink,
    getReferenceDefectTypeMaterialLink,
    getReferenceDefectTypeCaseLink,
    getReferenceLocalizationLink,
    getReferenceEquipmentLink,
    getReferenceFormsLink,
    getSettingsLink,
    getMicroscopyLink,
    getLifetimeArchiveLink,
    getMortemArchiveLink,
    getProfileLink,
    getHelpLink,
    getMortemCasesLink,
    getMortemCaseLink,
    getReferencePositionsLink,
    getReferenceGenderLink,
    getSamplePreparationDefectsLink,
    getMedicalServiceLink,
    getSettingsRequiredFieldsLink,
    getSettingsOrganizationsLink,
    getSettingsUsersLink,
    getSettingsUserCreateLink,
    getSettingsUserEditLink,
    getSettingsProductionCalendarLink,
    getSettingsRegistrationNumbersLink,
    getSettingsEmployeesLink,
    getSettingsFieldsTemplatesLink,
    getWizardLink,
    getSettingsPermissionsLink
} from "./links";


const routes = [{
        path: getAuthorizationLink(),
        name: "authorization",
        component: Authorization,
        meta: {
            requiresAuth: false,
            layout: "page-layout"
        }
    },
    {
        path: getAboutLink(),
        name: "about",
        component: () => import( /* webpackChunkName: "about" */ "@/views/About.vue"),
        meta: {
            requiresAuth: false,
            layout: "page-layout"
        }
    },
    {
        path: getSupportLink(),
        name: "support",
        component: () => import( /* webpackChunkName: "support" */ "@/views/Support.vue"),
        meta: {
            requiresAuth: false,
            layout: "page-layout"
        }
    },
    {
        path: getLifetimeCasesLink(),
        name: "lifetimeCases",
        component: () => import( /* webpackChunkName: "lifetimeCases" */ "@/views/LifetimeCases.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_lifecase_journal"
        }
    },
    {
        path: getLifetimeCaseLink(),
        name: "lifetimeCaseById",
        component: () => import( /* webpackChunkName: "lifetimeCaseById" */ "@/views/LifetimeCaseById.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_lifecase"
        },
        props: true
    },
    {
        path: getLifetimeCaseMacroDescriptionPhoto(),
        name: "lifetimeCaseMacroDescriptionPhotoById",
        component: () => import( /* webpackChunkName: "lifetimeCaseById" */ "@/views/LifetimeCaseMacroDescriptionPhotoById.vue"),
        meta: {
            requiresAuth: true,
            layout: "page-layout",
            permission: "view_macrocopy_image"
        },
        props: true
    },
    {
        path: getLifetimeCaseMicroscopyMicropreparationPhoto(),
        name: "lifetimeCaseMicroscopyMicropreparationPhotoById",
        component: () => import( /* webpackChunkName: "lifetimeCaseById" */ "@/views/LifetimeCaseMacroDescriptionPhotoById.vue"),
        meta: {
            requiresAuth: true,
            layout: "page-layout",
            permission: "view_microscopy_image"
        },
        props: true
    },
    {
        path: getLifetimeCasesSamplePreparationLink(),
        name: "lifetimeCasesSamplePreparation",
        component: () => import( /* webpackChunkName: "lifetimeCasesSamplePreparation" */ "@/views/LifetimeCasesSamplePreparation.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_sample_preparation_journal"
        }
    },
    {
        path: getSemdIntegrationLink(),
        name: "semdIntegration",
        component: () => import( /* webpackChunkName: "lifetimeCasesSamplePreparation" */ "@/views/SemdIntegration.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_semd_journal"
        }
    },
    {
        path: getMortemCasesLink(),
        name: "mortemCases",
        component: () => import( /* webpackChunkName: "mortemCases" */ "@/views/MortemCases.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout"
        },
    },
    {
        path: getMortemCaseLink(),
        name: "mortemCaseById",
        component: () => import( /* webpackChunkName: "mortemCaseById" */ "@/views/MortemCaseById.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout"
        },
        props: true
    },
    {
        path: getMetricsLink(),
        name: "metrics",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/Metrics.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_metrics"
        },
    },
    {
        path: getReferenceLink(),
        name: "reference",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/Reference.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
    },
    {
        path: getSamplePreparationDefectsLink(),
        name: "samplePreparationDefects",
        component: () => import( /* webpackChunkName: "mortemCaseById" */ "@/views/ReferenceSamplePreparationDefect.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getMedicalServiceLink(),
        name: "medicalService",
        component: () => import( /* webpackChunkName: "mortemCaseById" */ "@/views/ReferenceMedicalService.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceLocalizationLink(),
        name: "localizations",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceLocalization.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceEquipmentLink(),
        name: "equipment",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceEquipment.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceStaffLink(),
        name: "staff",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceStaff.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceEmployeePositionLink(),
        name: "employeePosition",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceEmployeePosition.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceOrganizationLink(),
        name: "organizations",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceOrganization.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceMaterialLink(),
        name: "biopsyMaterial",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceMaterial.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceBiopsyTypeLink(),
        name: "biopsyType",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceBiopsyType.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceColoringMethodsLink(),
        name: "coloringMethods",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceColoringMethods.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceColoringsLink(),
        name: "colorings",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceColorings.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceDepartmentLink(),
        name: "departments",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceDepartment.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceDefectTypeMaterialLink(),
        name: "defectTypeMaterial",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceDefectTypeMaterial.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceDefectTypeCaseLink(),
        name: "defectTypeCase",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceDefectTypeCase.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceFormsLink(),
        name: "forms",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceForm.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferencePositionsLink(),
        name: "positions",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferencePosition.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getReferenceGenderLink(),
        name: "gender",
        component: () => import( /* webpackChunkName: "reference" */ "@/views/ReferenceGender.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_dictionary_list"
        },
        props: true
    },
    {
        path: getSettingsLink(),
        name: "settings",
        component: () => import( /* webpackChunkName: "settings" */ "@/views/Settings.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_settings_list"
        },
    },
    {
        path: getSettingsRequiredFieldsLink(),
        name: "settingsRequiredFields",
        component: () => import( /* webpackChunkName: "settings" */ "@/views/SettingsRequiredFields.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_settings_list"
        },
    },
    {
        path: getSettingsOrganizationsLink(),
        name: "settingsOrganizations",
        component: () => import( /* webpackChunkName: "settings" */ "@/views/SettingsOrganizations.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_own_organization"
        },
    },
    {
        path: getSettingsUsersLink(),
        name: "settingsUsers",
        component: () => import( /* webpackChunkName: "settings" */ "@/views/SettingsUsers.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_user_page"
        },
    },
    {
        path: getSettingsUserCreateLink(),
        name: "settingsUserCreate",
        component: () => import( /* webpackChunkName: "settings" */ "@/views/SettingsUserCreate.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "add_user"
        },
    },
    {
        path: getSettingsUserEditLink(),
        name: "settingsUserEdit",
        component: () => import( /* webpackChunkName: "settings" */ "@/views/SettingsUserEdit.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "edit_user"
        },
    },
    {
        path: getSettingsPermissionsLink(),
        name: "settingsPermissions",
        component: () => import( /* webpackChunkName: "settings" */ "@/views/SettingsPermissions.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_permission_page"
        },
    },
    {
        path: getSettingsProductionCalendarLink(),
        name: "productionCalendar",
        component: () => import( /* webpackChunkName: "settings" */ "@/views/SettingsProductionCalendar.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_production_calendar"
        },
    },
    {
        path: getSettingsRegistrationNumbersLink(),
        name: "settingsRegistrationNumbers",
        component: () => import( /* webpackChunkName: "settings" */ "@/views/SettingsRegistrationNumbers.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_registration_number_template"
        },
    },
    {
        path: getSettingsEmployeesLink(),
        name: "settingsEmployees",
        component: () => import( /* webpackChunkName: "settings" */ "@/views/SettingsEmployees.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_employee_page"
        },
    },
    {
        path: getSettingsFieldsTemplatesLink(),
        name: "settingsFieldsTemplates",
        component: () => import( /* webpackChunkName: "settings" */ "@/views/SettingsFieldsTemplates.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout",
            permission: "view_settings_list"
        },
    },
    {
        path: getMicroscopyLink(),
        name: "microscopy",
        component: () => import( /* webpackChunkName: "microscopy" */ "@/views/Microscopy.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout"
        }
    },
    {
        path: getLifetimeArchiveLink(),
        name: "lifetimeCasesArchive",
        component: () => import( /* webpackChunkName: "lifetimeCasesArchive" */ "@/views/LifetimeCasesArchive.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout"
        }
    },
    {
        path: getMortemArchiveLink(),
        name: "mortemCasesArchive",
        component: () => import( /* webpackChunkName: "mortemCasesArchive" */ "@/views/MortemCasesArchive.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout"
        }
    },
    {
        path: getProfileLink(),
        name: "profile",
        component: () => import( /* webpackChunkName: "profile" */ "@/views/Profile.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout"
        }
    },
    {
        path: getHelpLink(),
        name: "help",
        component: () => import( /* webpackChunkName: "profile" */ "@/views/Help.vue"),
        meta: {
            requiresAuth: true,
            layout: "admin-layout"
        }
    },
    {
        path: getWizardLink(),
        name: "wizard",
        component: () => import( /* webpackChunkName: "wizard" */ "@/views/Wizard.vue"),
        meta: {
            requiresAuth: true,
            layout: "page-layout",
            permission: "start_initial_wizzard"
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () => import( /* webpackChunkName: "notFound" */ "@/views/NotFound.vue"),
        meta: {
            layout: "page-layout"
        }
    },
    {
        path: "/notPermission",
        name: "notPermission",
        component: () => import( /* webpackChunkName: "notFound" */ "@/views/NotPermissionPage.vue"),
        meta: {
            layout: "admin-layout"
        }
    },
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

let lastValidRoute = null; // Хранение последнего корректного маршрута

function checkUserPermission (to, next, permissions) {
    if (store.getters.getProfileData.is_superuser) { // Если пользователь является админом
        next();
    } else {
        const routePermission = to.meta.permission;
        if (!permissions[routePermission] && routePermission !== undefined) {
            next({path: "/notPermission"});
        } else {
            next();
        }
    }
}

router.beforeEach(async (to, from, next) => {
    const sessionStorageToken = localStorage.getItem('accessTokenData');
    const isAuthorizedUser = localStorage.getItem('isAuthorizedUser');
    // Сохраняем предыдущий маршрут, если это не системное перенаправление
    if (from.path !== '/notPermission') {
        lastValidRoute = from;
    }
    if (to.name === 'authorization') {
        // Если мы переходим на страницу авторизации и есть токен, редиректим на главную страницу
        if (sessionStorageToken && isAuthorizedUser) {
          next(getLifetimeCasesLink());
        } else {
          // Иначе, разрешаем доступ к странице авторизации
          next();
        }
    } else {
        // Если маршрут требует аутентификации и токен отсутствует, редиректим на страницу авторизации
        if (to.matched.some(record => record.meta.requiresAuth) && !sessionStorageToken) {
            next(getAuthorizationLink());
        } else {
        // Иначе, разрешаем доступ и переходим к следующему хуку (beforeResolve)
            next();
        }
    }
});
router.beforeResolve(async (to, from, next) => {
    if (to.name === 'authorization') {
        next();
    } else {
        if (store.getters.getCurrentUserPermissions === null) {// если нет объекта прав, то делаем запрос получаем его и делаем проверку прав
            await store.dispatch("fetchProfileData").then(() => {
                checkUserPermission(to, next, store.getters.getCurrentUserPermissions);
            })
        } else { // иначе просто делаем проверку прав
            checkUserPermission(to, next, store.getters.getCurrentUserPermissions);
        }
    }
})

export { lastValidRoute };

export default router;

export * from "./links";