<template>
    <div
        v-if="formConfirmationShowStatus"
        class="warning__container"
        :class="formConfirmationClasses"
    >
        <div
            class="warning__block"
            :class="{'warning__block_position': !formConfirmationButtonBlockDisable} && handleWarningBlockClasses"
        >
            <i
                class="warning__icon"
                v-html="getIconTemplate(formConfirmationIcon)"
            ></i>
            <p class="warning__block-text">
                {{ formConfirmationQuestionText }}
            </p>
        </div>
        <div
            class="warning__block_button--box"
        >
            <Button
                v-if="formConfirmationLeftButtonName || formConfirmationButtonLeftAction"
                style="margin-left: 8px;"
                :class="handleButtonLeftClasses"
                :label="formConfirmationLeftButtonName"
                :disabled="formConfirmationLeftButtonDisableStatus"
                @click="formConfirmationButtonLeftAction()"
            />
            <Button
                v-if="formConfirmationRightButtonName || formConfirmationButtonRightAction"
                style="margin-left: 8px;"
                :class="handleButtonRightClasses"
                :label="formConfirmationRightButtonName"
                :disabled="formConfirmationRightButtonDisableStatus"
                @click="formConfirmationButtonRightAction()"
            />
        </div>
    </div>
</template>

<script>
import { icons } from "@/mixins";

export default {
    name: "AppFormActionConfirmation",
    mixins: [icons],
    props: {
        formConfirmationQuestionText: String,
        formConfirmationLeftButtonName: String,
        formConfirmationRightButtonName: String,
        formConfirmationButtonLeftAction: Function,
        formConfirmationButtonRightAction: Function,
        formConfirmationButtonLeftKey: String,
        formConfirmationButtonRightKey: String,
        formConfirmationShowStatus: Boolean,
        formConfirmationButtonBlockDisable: Boolean,
        formConfirmationClasses: Array,
        formConfirmationTheme: String,
        formConfirmationIcon: String,
        formConfirmationRightButtonDisableStatus: Boolean,
        formConfirmationLeftButtonDisableStatus: Boolean,
        formConfirmationButtonsTheme: String
    },
    computed: {
        handleWarningBlockClasses() {
            if (this.formConfirmationTheme === "blue") {
                return "warning__block_blue"
            } else if (this.formConfirmationTheme === "green") {
                return "warning__block_green"
            } else if (this.formConfirmationTheme === "red") {
                return "warning__block_red"
            }
            return "warning__block_orange"
        },
        handleWarningIconClasses() {
            if (this.formConfirmationTheme === "blue") {
                return "warning__icon_blue"
            }
            return "warning__icon_orange"
        },
        handleButtonRightClasses() {
            if (this.formConfirmationButtonsTheme === "blue") {
                return "button__blue"
            } else if (this.formConfirmationButtonsTheme === "green") {
                return "button__green"
            } else if (this.formConfirmationButtonsTheme === "purple") {
                return "button__purple"
            } else if (this.formConfirmationButtonsTheme === "red") {
                return "button__red"
            }
            return "button__orange"
        },
        handleButtonLeftClasses() {
            if (this.formConfirmationTheme === "blue") {
                return "button__blue_border"
            } else if (this.formConfirmationTheme === "green") {
                return "button__green_border"
            } else if (this.formConfirmationTheme === "red") {
                return "button__red_border"
            }
            return "button__orange_border"
        }
    },
}
</script>

<style lang="sass">
@import "@/assets/css/_vars.sass"
.form__confirmation
    position: relative
.warning__container
    display: flex
    justify-content: space-between
    // position: absolute
    // width: 100%
    &_shifted--up
        top: -55px
    &_shifted--down
        bottom: 0
        z-index: 1
        background-color: white
    &_margin
        margin: 10px 16px 10px 16px
.warning__block
    display: flex
    height: 38px
    padding: 9px 20px
    align-items: center
    gap: 12px
    width: 100%
    border-radius: 4px
    &_blue
        background-color: $lavender
        border-left: 3px solid $moderateSlateBlue
    &_green
        background-color: $notificationSuccessPatientBackgroundColor
        border-left: 3px solid $notificationPatientSuccessBorderColor
    &_orange
        background-color: $appFormActionConfirmationBackgroundColor
        border-left: 3px solid $appFormActionConfirmationBorderColor
    &_red
        background-color: $palePink
        border-left: 3px solid $errorRed
    &-text
        color: $appFormActionConfirmationTextColor
        font-size: 12px
        font-style: normal
        font-weight: 700
        line-height: 18px
    &_button--box
        display: flex
        justify-content: flex-end
    &_position
        // position: absolute
.warning__button
    margin-left: 10px
.warning__icon
    padding-top: 3px
</style>