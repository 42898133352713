import { lifetimeApi } from "@/api";

export default {
    state: {
        lifetimeCaseInformationFiles: [],
        lifetimeInforamtionFilesLoader: false
    },
    actions: {
        async fetchLifetimeCaseInformationFiles({ dispatch, commit }, lifetimeCaseId) {
            commit("updateLifetimeInforamtionFilesLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const files = await lifetimeApi.getLifetimeCaseInformationFiles(lifetimeCaseId, token);
                    commit("changeLifetimeCaseInformationFiles", files);
                    commit("updateLifetimeInforamtionFilesLoader", false);
                }else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateLifetimeInforamtionFilesLoader", false);
                    return;
                }
            } catch (e) {
                console.error(e);
                commit("updateLifetimeInforamtionFilesLoader", false);
            }
        },
        async addLifetimeCaseInformationFiles({ commit, dispatch }, [lifetimeCaseId, formData]) {
            commit("updateLifetimeInforamtionFilesLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const files = await lifetimeApi.postLifetimeCaseInformationFiles(lifetimeCaseId, token, formData);
                    commit("updateLifetimeCaseInformationFiles", files.attachments);
                    commit("updateLifetimeInforamtionFilesLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateLifetimeInforamtionFilesLoader", false);
                    return;
                }
            } catch (e) {
                console.error(e);
                commit("updateLifetimeInforamtionFilesLoader", false);
            }
        },
        async deleteLifetimeCaseInformationFile({ commit, dispatch }, [lifetimeCaseId, attachmentId]) {
            commit("updateLifetimeInforamtionFilesLoader", true);
            try {
                const token = await dispatch("getAccessToken");
                if (token) {
                    const fileForDeleting = await lifetimeApi.deleteLifetimeCaseInformationFiles(lifetimeCaseId, attachmentId, token);
                    console.log(fileForDeleting);
                    commit("changeDeleteLifetimeCaseInformationFile", attachmentId);
                    commit("updateLifetimeInforamtionFilesLoader", false);
                } else {
                    dispatch("getRedirectAuthAfterInvalidRefreshTokenAction");
                    commit("updateLifetimeInforamtionFilesLoader", false);
                    return;
                }
            } catch (e) {
                console.error(e);
                commit("updateLifetimeInforamtionFilesLoader", false);
            }
        }
    },
    mutations: {
        changeLifetimeCaseInformationFiles(state, lifetimeCaseInformationFiles) {
            state.lifetimeCaseInformationFiles = lifetimeCaseInformationFiles;
        },
        updateLifetimeCaseInformationFiles(state, lifetimeCaseInformationFiles) {
            state.lifetimeCaseInformationFiles = [...state.lifetimeCaseInformationFiles, ...lifetimeCaseInformationFiles];
        },
        changeDeleteLifetimeCaseInformationFile(state, attachmentId) {
            const fileForDeletingIndex = state.lifetimeCaseInformationFiles.findIndex(
                file => file.id === attachmentId    
            );
            state.lifetimeCaseInformationFiles.splice(fileForDeletingIndex, 1);
        },
        updateLifetimeInforamtionFilesLoader(state, lifetimeInforamtionFilesLoader) {
            state.lifetimeInforamtionFilesLoader = lifetimeInforamtionFilesLoader;
        }
    },
    getters: {
        getLifetimeInformationFiles(state) {
            return state.lifetimeCaseInformationFiles;
        },
        getLifetimeInforamtionFilesLoader(state) {
            return state.lifetimeInforamtionFilesLoader;
        }
    },
}